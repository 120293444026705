import { Col, Form, FormGroup, Label, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { NoAccessTooltip } from '../../../containers/NoAccessTooltip';
import React from 'react';
import Select from 'react-select';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { WorkspaceActionsInfo } from '../../../../modules/team/workspaceActionsInfo/WorkspaceActionsInfo';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { handleSelectChange } from '../../../helpers/formHelpers';
import { withNamespaces } from 'react-i18next';

export interface IAddTeamMemberProps {
  currentWorkspaceId: string;
  email: string;
  emailStatus: InputStatus;
  selectedRole: WorkspaceRole;
  canAddUsers: boolean;
  errorContext: any;
  onRoleSelected(role: WorkspaceRole): void;
  onAddNewUser(): void;
  onEmailChange(email: string): void;
  clearErrors(): void;
}

interface IState {
  workspaceId: string;
  selectedRole: WorkspaceRole;
}

type Props = IAddTeamMemberProps & ITranslatable;

export const rolesPossibleToAssign: WorkspaceRole[] = [
  WorkspaceRole.Manager,
  WorkspaceRole.Developer,
  WorkspaceRole.Collaborator,
  WorkspaceRole.Guest,
  WorkspaceRole.Assistant,
  WorkspaceRole.Worker,
];

class AddTeamMemberPure extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      workspaceId: '',
      selectedRole: WorkspaceRole.Collaborator,
    };
  }

  static getDerivedStateFromProps(props: Props, state: IState): Partial<IState> | null {
    const newState: Partial<IState> = { selectedRole: props.selectedRole };

    if (props.currentWorkspaceId && props.email && props.currentWorkspaceId !== state.workspaceId) {
      props.clearErrors();
      newState.workspaceId = props.currentWorkspaceId;
    }

    if (
      (state.selectedRole === WorkspaceRole.Worker && props.selectedRole !== WorkspaceRole.Worker) ||
      (state.selectedRole !== WorkspaceRole.Worker && props.selectedRole === WorkspaceRole.Worker)
    ) {
      props.clearErrors();
    }

    return newState;
  }

  render() {
    const { t, canAddUsers } = this.props;

    return (
      <Form className="custom-inline-form add-new-user">
        <Row>
          <Col md={3}>
            <ValidatedTextInput
              id="new-user-email-input"
              labelText={this.props.t('add_new_user')}
              value={this.props.email}
              onChange={this.props.onEmailChange}
              feedbacks={this.props.emailStatus.errorCodes}
              feedbacksParams={{ plan: this.props.t(this.props.errorContext?.plan || 'plan') }}
              isValid={this.props.emailStatus.isValid}
              placeholder={this.props.t('email')}
              readOnly={!canAddUsers}
            />
          </Col>
          <Col md={3}>
            <FormGroup>
              <div className="d-flex">
                <Label>{this.props.t('select_role')}</Label>
                <WorkspaceActionsInfo />
              </div>

              <Select
                id="user-workspace-role-dropdown"
                value={{ label: t(this.props.selectedRole), value: this.props.selectedRole }}
                onChange={handleSelectChange((value: WorkspaceRole) => this.props.onRoleSelected(value))}
                options={rolesPossibleToAssign.map(r => ({ label: t(r), value: r }))}
                classNamePrefix="custom-select"
                isDisabled={!canAddUsers}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label>&nbsp;</Label>
              <NoAccessTooltip hasAccess={canAddUsers}>
                <DisabledWhenWorkspaceOwnerPolicyExceeded>
                  <ButtonWithLoader
                    id="add-user-button"
                    color="primary"
                    onClick={this.props.onAddNewUser}
                    disabled={this.props.emailStatus.isValid !== true}
                    loaderKey="add-user-button"
                  >
                    {this.props.t('add')}
                  </ButtonWithLoader>
                </DisabledWhenWorkspaceOwnerPolicyExceeded>
              </NoAccessTooltip>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

export const AddTeamMember = as<React.ComponentClass<IAddTeamMemberProps>>(withNamespaces('common')(AddTeamMemberPure));

import { CacheManager, ICacheManager } from './cacheManager';
import { IApiPolicyProvider, IPolicy } from './api.policy';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { IAuthStoreSetter } from '../modules/auth/auth.store';
import { IConfiguration } from '../../configuration';
import { ICryptoService } from './crypto.service';
import { ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { StickerError } from '../models/error.model';
import _ from 'lodash';
import { decodeImageAsync } from '../helpers/imageDecoder.helpers';
import { delay } from '../helpers/function.helpers';
import fileDownload from 'js-file-download';
import { injectable } from 'inversify';
import { merge } from 'lodash/fp';
import { toMBytes } from '../modules/datesets/helpers/image.helpers';

export interface IImageFile {
  blob: Blob;
  attributes: IImageAttributes;
}

export interface IImageAttributes {
  channels: number;
  isCached: boolean;
  downloadSpeed?: number;
}

export interface IApiService {
  getUrl(suffix: string): string;
  getAsync<TResult>(urlSuffix: string, config?: AxiosRequestConfig, customPolicy?: IPolicy): Promise<TResult | StickerError>;
  getFileAsync(urlSuffix: string, config?: AxiosRequestConfig, customPolicy?: IPolicy, overrideMime?: string): Promise<void | StickerError>;
  getImageAsync(fullUrl: string, config?: AxiosRequestConfig, customPolicy?: IPolicy): Promise<IImageFile | StickerError>;
  postAsync<TData, TResult = {}>(urlSuffix: string, body?: TData, config?: AxiosRequestConfig, customPolicy?: IPolicy): Promise<TResult | StickerError>;
  config: IConfiguration;
}

@injectable()
export abstract class ApiServiceBase implements IApiService {
  constructor(
    public config: IConfiguration,
    protected authStore: IAuthStoreSetter,
    protected currentWorkspaceStore: ICurrentWorkspaceStore,
    protected apiPolicy: IApiPolicyProvider,
    protected cacheManager: ICacheManager,
    protected cryptoService: ICryptoService,
  ) {}

  private static currentImageRequests: string[] = [];
  private static currentImageRequestsDurations: { url: string; duration: number }[] = [];

  public abstract getUrl(urlSuffix: string): string;

  public async getAsync<TResult>(urlSuffix: string, config?: AxiosRequestConfig, customPolicy?: IPolicy): Promise<TResult | StickerError> {
    try {
      const result: AxiosResponse<TResult> = await axios(this.getUrl(urlSuffix), this.addCommonHeaderToConfig(config));
      this.updateAuthToken(result);
      return result.data;
    } catch (error) {
      return await this.handleError(error, customPolicy);
    }
  }

  public async getFileAsync(urlSuffix: string, config?: AxiosRequestConfig, customPolicy?: IPolicy, overrideMime?: string): Promise<void | StickerError> {
    try {
      const requestConfig = config || {};
      requestConfig.responseType = 'arraybuffer';

      const result: AxiosResponse = await axios(this.getUrl(urlSuffix), this.addCommonHeaderToConfig(requestConfig));
      this.updateAuthToken(result);
      fileDownload(result.data, this.getFileNameFromResponse(result), overrideMime);
    } catch (error) {
      return await this.handleError(error, customPolicy);
    }
  }

  public async getImageAsync(fullUrl: string, customPolicy?: IPolicy): Promise<IImageFile | StickerError> {
    const cacheAvailable = await CacheManager.checkCacheAvailabilityAsync();

    while (ApiServiceBase.currentImageRequests.includes(fullUrl)) {
      await delay(10);
    }

    if (this.currentWorkspaceStore.currentWorkspace?.encryption.encrypted) {
      while (!(await this.cryptoService.checkKey(this.currentWorkspaceStore.currentWorkspace.id, this.currentWorkspaceStore.currentWorkspace.id))) await delay(1000);
    }

    ApiServiceBase.currentImageRequests.push(fullUrl);

    if (cacheAvailable) {
      const cachedArrayBuffer = await this.cacheManager.tryGetFromCacheAsync(fullUrl);
      if (cachedArrayBuffer) {
        _.remove(ApiServiceBase.currentImageRequests, x => x === fullUrl);
        const duration = this.getCachedDuration(fullUrl);
        return {
          blob: new Blob([cachedArrayBuffer]),
          attributes: await this.getAttributesAsync(cachedArrayBuffer, true, duration),
        };
      }
    }

    const response = await this.fetchImageAsync(fullUrl, customPolicy);

    if (response instanceof StickerError || response.response.status !== 200) {
      _.remove(ApiServiceBase.currentImageRequests, x => x === fullUrl);
      if (response instanceof StickerError) {
        return response;
      }
      return new StickerError('Unknown error');
    }

    let arrayBufferResponse = await response.response.arrayBuffer();

    arrayBufferResponse = await this.cryptoService.decrypt(this.currentWorkspaceStore.currentWorkspace!.id, arrayBufferResponse);

    arrayBufferResponse = await this.removeExifTags(arrayBufferResponse);

    let savedInCache = false;

    if (cacheAvailable) {
      try {
        await this.cacheManager.makeRoomInCacheAsync(arrayBufferResponse.byteLength);
        await this.cacheManager.putInCacheAsync(fullUrl, arrayBufferResponse);
        savedInCache = true;
      } catch {}
    }

    this.setCachedDuration(fullUrl, response.duration);
    _.remove(ApiServiceBase.currentImageRequests, x => x === fullUrl);
    return {
      blob: new Blob([arrayBufferResponse]),
      attributes: await this.getAttributesAsync(arrayBufferResponse, savedInCache, response.duration),
    };
  }

  /*
  Ten kod powstał po to, aby wyświetlać czas pobierania zdjęć w przypadku, kiedy użytkownik otwierał zdjęcie, które już było w trakcie pobierania przez eager loading.
  Zasada działania: po pobraniu zdjęcia przez eager loading do tablicy wrzucana jest jego prędkość pobierania.
  Kolejne żądanie pobrania zdjęcia sprawdza czy w tablicy jest czas pobierania zdjęcia, jeśli jest to go wyświetla i usuwa z tablicy.
  */
  private getCachedDuration(url: string) {
    const index = ApiServiceBase.currentImageRequestsDurations.findIndex(x => x.url === url);
    let duration: number | undefined = undefined;
    if (index > -1) {
      duration = ApiServiceBase.currentImageRequestsDurations[index].duration;
      ApiServiceBase.currentImageRequestsDurations.splice(index, 1);
    }
    return duration;
  }

  private setCachedDuration(url: string, duration: number) {
    ApiServiceBase.currentImageRequestsDurations.push({ url, duration });
  }

  private async getAttributesAsync(arrayBuffer: ArrayBuffer, isCached: boolean, downloadDuration?: number) {
    const metadata = await decodeImageAsync(arrayBuffer);

    const speed = downloadDuration ? (toMBytes(arrayBuffer.byteLength) * 8) / (downloadDuration / 1000) : downloadDuration;

    let channels = metadata.channels;

    if (!channels) {
      const colorType = metadata.colorType;
      switch (colorType) {
        case 0:
        case 3:
          channels = 1;
          break;
        case 2:
          channels = 3;
          break;
        case 4:
          channels = 2;
          break;
        case 6:
          channels = 4;
          break;
      }
    }

    return {
      isCached,
      channels: channels || 0,
      downloadSpeed: speed,
    } as IImageAttributes;
  }

  public async postAsync<TData, TResult = {}>(urlSuffix: string, body?: TData | undefined, config?: AxiosRequestConfig, customPolicy?: IPolicy): Promise<TResult | StickerError> {
    try {
      const result: AxiosResponse<TResult> = await axios.post(this.getUrl(urlSuffix), body, this.addCommonHeaderToConfig(config));
      this.updateAuthToken(result);
      return result.data;
    } catch (error) {
      return await this.handleError(error, customPolicy);
    }
  }

  public async upload<TData, TResult = {}>(urlSuffix: string, body?: TData | undefined, config?: AxiosRequestConfig, customPolicy?: IPolicy): Promise<TResult | StickerError> {
    try {
      const result: AxiosResponse<TResult> = await axios.post(this.getUrl(urlSuffix), body, this.addCommonHeaderToConfig(config));
      this.updateAuthToken(result);
      return result.data;
    } catch (error) {
      return await this.handleError(error, customPolicy);
    }
  }

  private async fetchImageAsync(fullUrl: string, customPolicy?: IPolicy): Promise<{ response: Response; duration: number } | StickerError> {
    try {
      const downloadStartTimestamp = performance.now();
      const response = await fetch(fullUrl, { headers: new Headers({ Authorization: `Bearer ${this.authStore.token}` }) });
      const downloadEndTimestamp = performance.now();
      return { response, duration: downloadEndTimestamp - downloadStartTimestamp };
    } catch (error) {
      return await this.handleError(error, customPolicy);
    }
  }

  private getFileNameFromResponse(response: AxiosResponse) {
    const disposition = response.request.getResponseHeader('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename\*=UTF-8''([^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        return decodeURI(matches[1]);
      }
    }
    return '';
  }

  private addCommonHeaderToConfig(config: AxiosRequestConfig = {}) {
    const newConfig = !!this.authStore.token ? merge(config, { headers: { Authorization: `Bearer ${this.authStore.token}` } }) : config;

    return merge(newConfig, { headers: { Server: this.config.version } });
  }

  private updateAuthToken(response: AxiosResponse) {
    if (response.headers['authorization']) {
      this.authStore.updateToken(response.headers['authorization']);
    }
  }

  private async handleError(error: any, customPolicy?: IPolicy) {
    if (error.response) {
      const effectivePolicy: IPolicy = Object.assign(this.apiPolicy.getGlobal(), customPolicy || {});

      if (error.response.status in effectivePolicy) {
        return await effectivePolicy[error.response.status](error);
      }

      return new StickerError(error.statusText, error.response.data);
    }
    return new StickerError(error.statusText, undefined);
  }

  private async removeExifTags(buffer: ArrayBuffer): Promise<ArrayBuffer> {
    const dv = new DataView(buffer);
    let offset = 0;
    let recess = 0;
    const pieces: any[] = [];
    let i = 0;
    if (dv.getUint16(offset) === 0xffd8) {
      offset += 2;
      let app1 = dv.getUint16(offset);
      offset += 2;
      while (offset < dv.byteLength) {
        if (app1 === 0xffe1) {
          pieces[i] = { recess, offset: offset - 2 };
          recess = offset + dv.getUint16(offset);
          i += 1;
        } else if (app1 === 0xffda) {
          break;
        }
        offset += dv.getUint16(offset);
        app1 = dv.getUint16(offset);
        offset += 2;
      }
      if (pieces.length > 0) {
        const newPieces: ArrayBuffer[] = [];
        pieces.forEach(v => {
          newPieces.push(buffer.slice(v.recess, v.offset));
        });
        newPieces.push(buffer.slice(recess));

        return await new Blob(newPieces).arrayBuffer();
      }
    }
    return buffer;
  }
}

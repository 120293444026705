import { AdminPath } from './Admin';
import { AnnotationsPath } from './Annotations';
import { AttachmentsPath } from './Attachments';
import { BaseRouteConfig } from './BaseRoute';
import { DatasetsPath } from './Datasets';
import { FreeAccessPath } from './FreeAccess';
import { IntegrationsPath } from './Integrations';
import { LockedPath } from './Locked';
import { ProjectsPath } from './Projects';
import { TeamPath } from './Team';
import { UserPath } from './User';
import { WorkspacesPath } from './Workspaces';

class HomeRouteConfig extends BaseRouteConfig {
  public get Index(): string {
    return '/home/:workspaceId/';
  }

  public readonly Annotations: AnnotationsPath = new AnnotationsPath(this.Path);

  public readonly FreeAccess: FreeAccessPath = new FreeAccessPath(this.Path);

  public readonly Projects: ProjectsPath = new ProjectsPath(this.Path);

  public readonly Datasets: DatasetsPath = new DatasetsPath(this.Path);

  public readonly User: UserPath = new UserPath(this.Path);

  public readonly Integrations: IntegrationsPath = new IntegrationsPath(this.Path);

  public readonly Admin: AdminPath = new AdminPath(this.Path);

  public readonly Attachments: AttachmentsPath = new AttachmentsPath(this.Path);

  public readonly Team: TeamPath = new TeamPath(this.Path);

  public readonly Workspaces: WorkspacesPath = new WorkspacesPath(this.Path);

  public readonly Locked: LockedPath = new LockedPath(this.Path);
}

export const Home = new HomeRouteConfig('');

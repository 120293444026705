import { GTable, IGColumnProps, IGRowModel } from '../../../../__legacy__/components/table/GTable';
import { IWorkspaceAction, WorkspaceActions } from '../models/WorkspaceActions';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { nameOf } from '../../../../__legacy__/helpers/object.helpers';

type IWorkspaceActionRowModel = IWorkspaceAction & IGRowModel;
const WorkspaceActionsTablePure: React.FC<WithNamespaces> = props => {
  const { t } = props;

  const items: IWorkspaceActionRowModel[] = WorkspaceActions.map(wa => ({
    id: wa.actionType.toString(),
    ...wa,
  }));

  const columns: IGColumnProps<IWorkspaceAction>[] = [
    {
      field: nameOf<IWorkspaceAction>('actionType'),
      headerName: props.t('team.workspace_actions.table.headers.actionType'),
      renderer: (item: IWorkspaceAction) => <div className="text-justify">{t(`team.workspace_actions.table.action_types.${item.actionType.toString()}`)}</div>,
      width: 470,
    },
    {
      field: nameOf<IWorkspaceAction>('worker'),
      headerName: props.t('team.workspace_actions.table.headers.worker'),
      renderer: (item: IWorkspaceAction) => renderPermission(item.worker),
      cellStyle: () => ({ whiteSpace: 'initial' }),
      width: 120,
    },
    {
      field: nameOf<IWorkspaceAction>('assistant'),
      headerName: props.t('team.workspace_actions.table.headers.assistant'),
      renderer: (item: IWorkspaceAction) => renderPermission(item.assistant),
      cellStyle: () => ({ whiteSpace: 'initial' }),
      width: 180,
    },
    {
      field: nameOf<IWorkspaceAction>('guest'),
      headerName: props.t('team.workspace_actions.table.headers.guest'),
      renderer: (item: IWorkspaceAction) => renderPermission(item.guest),
      cellStyle: () => ({ whiteSpace: 'initial' }),
      width: 90
    },
    {
      field: nameOf<IWorkspaceAction>('collaborator'),
      headerName: props.t('team.workspace_actions.table.headers.collaborator'),
      renderer: (item: IWorkspaceAction) => renderPermission(item.collaborator),
      cellStyle: () => ({ whiteSpace: 'initial' }),
      width: 160,
    },
    {
      field: nameOf<IWorkspaceAction>('developer'),
      headerName: props.t('team.workspace_actions.table.headers.developer'),
      renderer: (item: IWorkspaceAction) => renderPermission(item.developer),
      cellStyle: () => ({ whiteSpace: 'initial' }),
      width: 160,
    },
    {
      field: nameOf<IWorkspaceAction>('manager'),
      headerName: props.t('team.workspace_actions.table.headers.manager'),
      renderer: (item: IWorkspaceAction) => renderPermission(item.manager),
      cellStyle: () => ({ whiteSpace: 'initial' }),
      width: 90
    },
    {
      field: nameOf<IWorkspaceAction>('owner'),
      headerName: props.t('team.workspace_actions.table.headers.owner'),
      renderer: (item: IWorkspaceAction) => renderPermission(item.owner),
      cellStyle: () => ({ whiteSpace: 'initial' }),
      width: 90
    },
  ];

  const renderPermission = (value: string | boolean): React.ReactElement => {
    return typeof value === 'boolean' ? (
      <FontAwesomeIcon style={value ? {} : { transform: 'rotate(45deg)' }} icon={value ? faCheck : faPlus} />
    ) : (
      <>{t(`team.workspace_actions.table.${value}`)}</>
    );
  };

  return (
    <div className="workspace-actions-table-container">
      <GTable<IWorkspaceActionRowModel> columns={columns} items={items} />
    </div>
  );
};

export const WorkspaceActionsTable = withNamespaces('new')(WorkspaceActionsTablePure);

import { faChartBar, faFileCsv } from '@fortawesome/free-solid-svg-icons';

import { Bar } from 'react-chartjs-2';
import { Button } from 'reactstrap';
import { CustomScrollbar } from '../../../../../components/CustomScrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAnswerStatisticDto } from '../projectDetailsStatistics.model';
import { ITranslatable } from '../../../../../helpers/translations.helpers';
import { QuestionType } from '../../../../annotation/question.model';
import React from 'react';
import { as } from '../../../../../helpers/react.helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faChartBar, faFileCsv);

const MAX_VISIBLE_LABEL = 40;

interface IAnnotationProgressChartProps {
  answerStatistics: IAnswerStatisticDto;
  onGetAnswers(): void;
}

@observer
export class AnswersChartPure extends React.Component<IAnnotationProgressChartProps & ITranslatable> {

  render() {
    const { t } = { ...this.props };

    const sorted = this.props.answerStatistics.answers.slice().sort((x, y) => x.order - y.order);

    const attributeData = {
      labels: sorted.map(a => a.text),
      datasets: [
        {
          label: t('awaiting_review'),
          data: sorted.map(a => a.annotated),
          backgroundColor: '#4C516D',
          hoverBackgroundColor: '#4C516D',
        },
        {
          label: t('accepted'),
          data: sorted.map(a => a.approved),
          backgroundColor: '#70D499',
          hoverBackgroundColor: '#70D499',
        },
        {
          label: t('rejected'),
          data: sorted.map(a => a.rejected),
          backgroundColor: '#db5230',
          hoverBackgroundColor: '#db5230',
        },
      ],
    };

    const longestLabel = sorted.length ? Math.max(...sorted.map(x => x.text.length)) : 0;
    const width = sorted.length * 30;
    const height = 330 + Math.min(longestLabel, MAX_VISIBLE_LABEL) * 5;

    return (
      <div className="statistic-wrapper">
        <h5>{this.props.answerStatistics.text}</h5>
        <div className="statistics-body" style={{ height: height + 20 }}>
          <div className="statistics-summary">
            {this.props.answerStatistics.questionType === QuestionType.OPEN ?
              <div className="list">
                <span>
                  <Button
                    color="primary"
                    className="attachments-button upload"
                    onClick={this.props.onGetAnswers}
                  >
                    <FontAwesomeIcon icon={faFileCsv} />
                    {this.props.t('all_results')}
                  </Button>
                </span>
              </div> :
              < CustomScrollbar autoHide>
                <div className="list">
                  {sorted.map(a => <span key={a.id}>{a.text || t('answers')}: {a.annotated + a.approved + a.rejected}</span>)}
                </div>
              </CustomScrollbar>
            }
          </div>
          <div className="chart-wrapper">
            < CustomScrollbar autoHide={false}>
              <div className="statistics-chart wide" style={{ width, height }}>
                <Bar
                  data={attributeData}
                  options={{
                    maintainAspectRatio: false,
                    legend: {
                      display: true,
                      position: 'right',
                      align: 'center',
                    },
                    tooltips: {
                      mode: 'index',
                      intersect: false,
                      callbacks: {
                        label(tooltipItem: any, data: any) {
                          return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.yLabel}`;
                        },
                      },
                    },
                    scales: {
                      xAxes: [{
                        stacked: true,
                        gridLines: { display: false },
                        ticks: {
                          callback(value: string) {
                            return value.length > 40 ? `${value.substring(0, 38)}...` : value;
                          },
                        },
                      }],
                      yAxes: [{
                        stacked: true,
                        ticks: {
                          beginAtZero: true,
                          suggestedMin: 0,
                        },
                      }],
                    },
                  }}
                />
              </div>
            </CustomScrollbar>
          </div>
        </div>
      </div >);
  }
}

export const AnswersChart = as<React.ComponentClass<IAnnotationProgressChartProps>>(withNamespaces('common', { wait: true })(AnswersChartPure));

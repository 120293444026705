import * as React from 'react';

import { Link } from 'react-router-dom';
import { as } from '../helpers/react.helpers';

export type TabsSingleItemState =
  | 'active'
  | 'passive'
  | 'active validated'
  | 'active error'
  | 'passive validated'
  | 'passive error';

interface IProps {
  text: string;
  link: string;
  callback?(): void;
  state?: TabsSingleItemState;
}

const TabsSingleItemTranslated = (props: IProps) => (
  <li className={`${props.state}`}>
    <Link onClick={props.callback} to={`${props.link}`}>{props.text}</Link>
  </li>
);

export const TabsSingleItem = as<React.SFC<IProps>>(TabsSingleItemTranslated);

import { inject, injectable } from 'inversify';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserStore, UserStoreType } from '../user/user.store';
import { WorkspaceRole } from '../workspaces/workspaces.store';
import { DatasetStatus } from './datasetStatus.model';

export const DatasetsPermissionsType = Symbol('DATASETS_PERMISSIONS');

export interface IDatasetsPermissions {
  canRenameImages(datasetStatus: DatasetStatus, datasetCreator: string): boolean;
  canDeleteUnmarkedImages(datasetStatus: DatasetStatus, datasetCreator: string): boolean;
  canDeleteMarkedImages(): boolean;
  canDownloadImages(): boolean;
  canCreateDataset(): boolean;
  canUploadImages(datasetStatus: DatasetStatus, datasetCreatorId: string): boolean;
  canEditDatasetDetails(datasetStatus: DatasetStatus, datasetCreatorId: string): boolean;
  canSeeDatasetCreatedBy(): boolean;
  canPublishDataset(): boolean;
  canDownloadDataset(): boolean;
  canDeleteDataset(datasetStatus: DatasetStatus, datasetCreatorId: string): boolean;
}

@injectable()
export class DatasetsPermissions implements IDatasetsPermissions {
  constructor(@inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore, @inject(UserStoreType) private readonly userStore: IUserStore) {}

  canRenameImages(datasetStatus: DatasetStatus, datasetCreatorId: string) {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;

    const permittedRolesScope =
      datasetStatus === DatasetStatus.PUBLISHED
        ? [WorkspaceRole.Owner, WorkspaceRole.Manager]
        : [WorkspaceRole.Developer, WorkspaceRole.Collaborator, WorkspaceRole.Manager, WorkspaceRole.Owner];

    if ((datasetStatus !== DatasetStatus.PUBLISHED && datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING) && this.userStore.userInfo.id === datasetCreatorId) {
      permittedRolesScope.push(WorkspaceRole.Assistant);
    }

    return permittedRolesScope.includes(userRole);
  }

  canDeleteUnmarkedImages(datasetStatus: DatasetStatus, datasetCreatorId: string) {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator];

    if ((datasetStatus !== DatasetStatus.PUBLISHED && datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING) && this.userStore.userInfo.id === datasetCreatorId) {
      permittedRolesScope.push(WorkspaceRole.Assistant);
    }

    return permittedRolesScope.includes(userRole);
  }

  canDeleteMarkedImages() {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager];

    return permittedRolesScope.includes(userRole);
  }

  canDownloadImages() {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer];

    return permittedRolesScope.includes(userRole);
  }

  canCreateDataset() {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator, WorkspaceRole.Assistant];

    return permittedRolesScope.includes(userRole);
  }

  canUploadImages(datasetStatus: DatasetStatus, datasetCreatorId: string) {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope =
      datasetStatus === DatasetStatus.DRAFT
        ? [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator]
        : [WorkspaceRole.Owner, WorkspaceRole.Manager];

    if ((datasetStatus !== DatasetStatus.PUBLISHED && datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING) && this.userStore.userInfo.id === datasetCreatorId) {
      permittedRolesScope.push(WorkspaceRole.Assistant);
    }

    return permittedRolesScope.includes(userRole);
  }

  canSeeDatasetCreatedBy() {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer];

    return permittedRolesScope.includes(userRole);
  }

  canEditDatasetDetails(datasetStatus: DatasetStatus, datasetCreatorId: string) {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator];

    if ((datasetStatus !== DatasetStatus.PUBLISHED && datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING) && this.userStore.userInfo.id === datasetCreatorId) {
      permittedRolesScope.push(WorkspaceRole.Assistant);
    }

    return permittedRolesScope.includes(userRole);
  }

  canPublishDataset() {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator];

    return permittedRolesScope.includes(userRole);
  }

  canDownloadDataset() {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer];

    return permittedRolesScope.includes(userRole);
  }

  canDeleteDataset(datasetStatus: DatasetStatus, datasetCreatorId: string) {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRolesScope = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator];

    if ((datasetStatus !== DatasetStatus.PUBLISHED && datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING) && this.userStore.userInfo.id === datasetCreatorId) {
      permittedRolesScope.push(WorkspaceRole.Assistant);
    }

    return permittedRolesScope.includes(userRole);
  }
}

import autobind from 'autobind-decorator';
import { inject, injectable } from 'inversify';
import { IWorkspacesStore, WorkspaceRole, WorkspacesStoreType } from './workspaces.store';

export const WorkspacesPermissionsType = Symbol('WORKSPACES_PERMISSIONS');

export interface IWorkspacesPermissions {
  canAccessTeam(workspaceId: string): boolean;
  canAccessDatasets(workspaceId: string): boolean;
  canAccessAttachments(workspaceId: string): boolean;
}

@injectable()
@autobind
export class WorkspacesPermissions implements IWorkspacesPermissions {
  constructor(@inject(WorkspacesStoreType) private readonly workspaceStore: IWorkspacesStore) {}

  getWorkspaceRole(workspaceId: string): WorkspaceRole {
    return this.workspaceStore.workspaces.find(w => w.id === workspaceId)?.role ?? WorkspaceRole.Worker;
  }

  canAccessTeam(workspaceId: string) {
    const workspaceRole = this.getWorkspaceRole(workspaceId);
    return [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(workspaceRole);
  }

  canAccessDatasets(workspaceId: string) {
    const workspaceRole = this.getWorkspaceRole(workspaceId);
    return workspaceRole !== WorkspaceRole.Worker;
  }

  canAccessAttachments(workspaceId: string) {
    const workspaceRole = this.getWorkspaceRole(workspaceId);
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer].includes(workspaceRole);
  }
}

import './ImagesListThumbnail.scss';

import * as React from 'react';

import { IProjectDetailsImage, ImageAnnotationStatus, ImageSetType } from '../projectDetailsImages.model';
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { ClampLines } from '../../../../../components/ClampLines';
import { CustomInput } from 'reactstrap';
import { DatasetStatus } from '../../../../datesets/datasetStatus.model';
import { Home } from '../../../../../routes/config/Home';
import { LazyImage } from '../../../../../components/LazyImage';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { TranslationFunction } from 'i18next';
import { WarningIconBadge } from '../../../../../components/badges/iconBadges/WarningIconBadge';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import { handleSelectChange } from '../../../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';

library.add(faUnlink, faEdit);

interface ThumbnailProps {
  image: IProjectDetailsImage;
  filterId: string;
  t: TranslationFunction;
  disabled: boolean;
  projectId: string;
  workspaceId: string;
  authToken: string;
  showCheckbox: boolean;
  canSelectImages: boolean;
  canUpdateImageSet: boolean;
  isChecked: boolean;
  onCheck(id: string, isBatch: boolean): void;
  onImageSetChange(id: string, type: ImageSetType): void;
}

@observer
export class ImagesListThumbnail extends React.Component<ThumbnailProps> {
  componentDidMount() {
    // this will disable marking of texts in Chrome and Edge
    document.onselectstart = (e: any) => {
      if (e.target && e.target.classList && e.target.classList.value === 'custom-control-label') return false;
    };
  }

  @autobind
  getNavigationUrl(imageId: string) {
    const { workspaceId, projectId, filterId } = this.props;
    return Home.FreeAccess.View.withParams({ workspaceId, projectId, imageId, filterId });
  }

  renderImage = () => {
    return (
      <LazyLoadComponent threshold={300}>
        <LazyImage source={this.props.image.url} headers={new Headers({ Authorization: `Bearer ${this.props.authToken}` })} />
      </LazyLoadComponent>
    );
  };

  renderName = () => <ClampLines text={this.props.image.name} lines={1} />;

  renderDatasetName = () => {
    const { datasetStatus } = this.props.image;
    const showWarning = datasetStatus !== DatasetStatus.PUBLISHED && datasetStatus !== DatasetStatus.PUBLISHEDUPLOADING;

    return (
      <div className="dataset-name-with-icon">
        {showWarning && <WarningIconBadge label="" title={this.props.t('unpublished')} />}
        <ClampLines text={this.props.image.datasetName} lines={1} />
      </div>
    );
  };

  getOnChange = (id: string) => (e: any) => {
    if (this.props.canSelectImages && !this.props.image.isLocked) {
      this.props.onCheck(id, e.nativeEvent.shiftKey);
    }
  };

  stopPropagation(e: React.SyntheticEvent) {
    e.stopPropagation();
  }

  getImageStatusAndClass() {
    let textText;
    let statusClass;

    switch (this.props.image.status) {
      case ImageAnnotationStatus.TOREVIEW:
        textText = this.props.t('waiting_for_approval');
        statusClass = 'annotated';
        break;
      case ImageAnnotationStatus.ACCEPTED:
        textText = this.props.t('finished');
        statusClass = 'approved';
        break;
      case ImageAnnotationStatus.DRAFT:
        textText = this.props.t('waiting_for_submission');
        statusClass = 'not-submitted';
        break;
      case ImageAnnotationStatus.REJECTED:
        textText = this.props.t('waiting_for_correction');
        statusClass = 'rejected';
        break;
      case ImageAnnotationStatus.CLARIFICATION:
        textText = this.props.t('waiting_for_clarification');
        statusClass = 'clarification';
        break;
      default:
        textText = this.props.t('waiting_for_annotation');
        statusClass = 'not-annotated';
    }

    return { statusClass, textText };
  }

  render() {
    const hasClarification = this.props.image.status === ImageAnnotationStatus.CLARIFICATION;
    const { statusClass, textText } = this.getImageStatusAndClass();

    return (
      <div className={`thumb ${hasClarification ? 'clarification' : ''}`}>
        <div className="image-container">
          {this.props.disabled ? (
            this.renderImage()
          ) : (
            <Link className="text-link-in-table" to={this.getNavigationUrl(this.props.image.id)}>
              {' '}
              {this.renderImage()}
            </Link>
          )}
          <div className={`thumb-set-type-dropdown ${this.props.canUpdateImageSet ? '' : 'disabled'}`}>
            <Select
              id={`image-set-type-dropdown-${this.props.image.id}`}
              value={{ label: this.props.t(`image_set_options.${this.props.image.imageSet}`), value: this.props.image.imageSet }}
              onChange={handleSelectChange((value: ImageSetType) => this.props.onImageSetChange(this.props.image.id, value))}
              options={Object.values(ImageSetType).map(t => ({ label: this.props.t(`image_set_options.${t}`), value: t }))}
              classNamePrefix="custom-select"
              className={classNames({
                'not-set': this.props.image.imageSet === ImageSetType.NotSet,
                train: this.props.image.imageSet === ImageSetType.Train,
                ignore: this.props.image.imageSet === ImageSetType.Ignore,
                default: [ImageSetType.Private, ImageSetType.Test, ImageSetType.Validation].includes(this.props.image.imageSet),
              })}
              isDisabled={!this.props.canUpdateImageSet}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          {!this.props.disabled && this.props.showCheckbox && (
            <>
              {this.props.canSelectImages && (
                <div className="thumb-checkbox" onClick={this.getOnChange(this.props.image.id)} title={this.props.image.isLocked ? this.props.t('someone_is_working') : ''}>
                  <CustomInput
                    checked={this.props.isChecked}
                    onChange={this.stopPropagation}
                    onClick={this.stopPropagation}
                    value={this.props.image.id}
                    id={this.props.image.id}
                    disabled={this.props.image.isLocked}
                    className={`${this.props.isChecked ? 'visible' : ''} ${this.props.image.isLocked ? 'locked' : ''}`}
                    type="checkbox"
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="description">
          <div className="file-name line-clamp">
            {this.props.disabled ? (
              this.renderName()
            ) : (
              <Link className="text-link-in-table" title={this.props.image.name} to={this.getNavigationUrl(this.props.image.id)}>
                {this.renderName()}
              </Link>
            )}
          </div>
          <div className="dataset-name" title={this.props.image.datasetName}>
            {this.renderDatasetName()}
          </div>
        </div>
        <div className="attributes">
          <span className="attribute">
            {this.props.t('status')}:<span className={`annotation-status ${statusClass}`}> {textText}</span>
          </span>
        </div>
      </div>
    );
  }
}

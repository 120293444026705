import { IPagingInfoWithOrder, PaginationInfoDefault } from '../../models/paginationInfo.model';
import { action, observable } from 'mobx';

import { Home } from '../../routes/config/Home';
import { Plan } from '../user/user.store';
import { injectable } from 'inversify';

export const WorkspacesStoreType = Symbol('WORKSPACE_STORE');

type Unencrypted = { encrypted: false; encryptionAllowed: boolean };
type Encrypted = { encrypted: true; checksum: string; encryptionAllowed: boolean };
export type EncryptionMode = Unencrypted | Encrypted;

export enum WorkspaceRole {
  None = 'none',
  Owner = 'Owner',
  Manager = 'Manager',
  Developer = 'Developer',
  Collaborator = 'Collaborator',
  Guest = 'Guest',
  Worker = 'Worker',
  Assistant = 'Assistant',
}

export const NonWorkerRoles = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator, WorkspaceRole.Guest, WorkspaceRole.Assistant];

export const ExportRoles = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer];

export const ProjectManagementRoles = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer];

export interface IWorkspace {
  name: string;
  id: string;
  role: WorkspaceRole;
  locked: boolean;
  encryption: EncryptionMode;
  ownerAccountIsChargeable: boolean;
  ownerPlanTier: Plan;
  timeZoneOffset: number;
  isCreatedBeforeStatsRelease: boolean;
  ownerEmail: string;
}

export interface IWorkspaceListItem {
  id: string;
  name: string;
  role: string;
  createdDate: Date;
  owner: string;
  usersAndWorkersCount: IUsersAndWorkersCount;
  datasetsCount: number;
  imagesCount: number;
  projectsCount: number;
  isOwner: boolean;
  members: IWorkspaceMember[];
  isEncrypted: boolean;
  isLocked: boolean;
  timeZoneOffset: number;
}

export interface IUsersAndWorkersCount {
  usersCount: number;
  workersCount: number;
}

export interface IWorkspaceMember {
  id: string;
  name: string;
  email: string;
  role: WorkspaceRole;
  hasAccount: boolean;
}

export interface ILimitInfo {
  targetLimit: number;
  targetLimitUsage: number;
  workspaceLimitUsage: number;
}

export interface ITakeOverWorkspaceLimits {
  workspaces: ILimitInfo;
  users: ILimitInfo;
  images: ILimitInfo;
  storage: ILimitInfo;
  workers: ILimitInfo;
  canEncrypt: boolean;
  isAllowed: boolean;
}

export interface IWorkspacesStore {
  // permissions
  canAddWorkspace: boolean;

  // list
  workspaces: IWorkspace[];
  workspaceListItems: IWorkspaceListItem[];
  showLoader: boolean;
  workspacesPaging: IPagingInfoWithOrder;

  // search
  workspaceNameSearch: string;
  workspaceOwnerSearch: string;

  // current
  currentWorkspaceTeam: IWorkspaceMember[];
  readonly preferredWorkspaceId: string | undefined;

  takeOverWorkspaceLimits: ITakeOverWorkspaceLimits | undefined;

  addWorkspace(id: string, name: string, isChargeable: boolean, plan: Plan, timeZone: number, ownerEmail: string, checksum?: string): void;
  removeWorkspace(id: string): void;
  getPreferredOrDefaultWorkspaceId(): string | undefined;
  setForceRefresh(value: boolean): void;
  setPreferredWorkspace(workspaceId: string): void;
}

export const workspaceChangeEnablePaths: string[] = [Home.Projects.List.Path, Home.Integrations.Path, Home.Admin.Users.Path, Home.Workspaces.Path, Home.User.Path];

@injectable()
export class WorkspacesStore implements IWorkspacesStore {
  @observable
  workspaces: IWorkspace[] = [];

  @observable
  workspacesPaging: IPagingInfoWithOrder = {
    ...PaginationInfoDefault,
    orderBy: 'name',
    orderType: 'asc',
  };

  @observable.shallow
  workspaceListItems: IWorkspaceListItem[] = [];

  @observable
  showLoader: boolean = false;

  @observable
  workspaceNameSearch: string = '';

  @observable
  workspaceOwnerSearch: string = '';

  @observable
  canAddWorkspace: boolean = false;

  @observable
  currentWorkspaceTeam: IWorkspaceMember[] = [];

  @observable
  takeOverWorkspaceLimits: ITakeOverWorkspaceLimits | undefined = undefined;

  @observable
  preferredWorkspaceId: string | undefined;

  @observable
  forceRefresh: boolean = false;

  @action
  addWorkspace(id: string, name: string, isChargeable: boolean, plan: Plan, timeZoneOffset: number, ownerEmail: string, checksum?: string) {
    this.workspaces.push({
      id,
      name,
      timeZoneOffset,
      ownerEmail,
      role: WorkspaceRole.Owner,
      locked: false,
      ownerAccountIsChargeable: isChargeable,
      ownerPlanTier: plan,
      encryption: checksum ? { checksum, encrypted: true, encryptionAllowed: true } : { encrypted: false, encryptionAllowed: true },
      isCreatedBeforeStatsRelease: false,
    });
  }

  @action
  removeWorkspace(id: string) {
    this.workspaces = this.workspaces.filter((w: IWorkspace) => {
      return w.id !== id;
    });
  }

  @action
  getPreferredOrDefaultWorkspaceId(): string | undefined {
    if (this.workspaces.some(x => x.id === this.preferredWorkspaceId)) {
      return this.preferredWorkspaceId;
    }
    if (this.workspaces.length) {
      return this.workspaces[0].id;
    }

    return undefined;
  }

  @action
  setForceRefresh(value: boolean) {
    this.forceRefresh = value;
  }

  @action
  setPreferredWorkspace(workspaceId: string): void {
    this.preferredWorkspaceId = workspaceId;
  }
}

import * as React from 'react';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { faDownload, faList, faSortAmountDown, faTh, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { EnableForRole } from '../../../containers/EnableForRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDatasetDetailsImage } from '../datasetsDetails.store';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IUserPermissions } from '../../user/user.service';
import { IconedButton } from '../../../components/IconedButton';
import { ListViewMode } from '../../user/user.store';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { nameOf } from '../../../helpers/object.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faSortAmountDown, faTh, faList, faTrashAlt, faDownload);

export interface IDatasetDetailsImageListControlsProps {
  viewMode: ListViewMode;
  orderBy: string;
  orderDirection: string;
  onViewModeChange(mode: ListViewMode): void;
  onOrderByChange(order: string): void;
  onOrderDirectionChange(direction: string): void;
  onBulkDelete(): void;
  showBulkDeleteConfirmation: boolean;
  toggleConfirmationModal(): void;
  downloadImages(): void;
  selectedImagesCount: number;
  usedImagesCount: number;
  userPermissions: IUserPermissions;
}

const DatasetDetailsImageListControlsPure = (props: IDatasetDetailsImageListControlsProps & ITranslatable) => {
  const isAnyImageSelected = props.selectedImagesCount > 0;
  let deleteConfirmationBody: string;

  if (props.userPermissions.canDeleteAnyImageInDataset && props.usedImagesCount > 0) {
    deleteConfirmationBody = 'delete_locked_or_annotated_images_confirmation_body';
  } else if (props.userPermissions.canDeleteUnlockedAndUnannotatedImageInDataset && props.usedImagesCount > 0) {
    deleteConfirmationBody = 'delete_images_confirmation_body_with_used_images_count';
  } else {
    deleteConfirmationBody = 'delete_images_confirmation_body';
  }

  return (
    <div className="image-view-controls-container">

      {props.viewMode === ListViewMode.Tiles && <>
        <div className="image-view-control">
          <EnableForRole
            workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer]}
            projectRoles={[]}
            title={props.userPermissions.planAllowsDownloadImageInDataset ? props.t('disabled_due_to_insufficient_rights') : props.t('cant_download_with_annotate_and_go')}
          >
            <IconedButton
              color="primary"
              className="image-list-button download-button"
              icon={faDownload}
              title={props.userPermissions.planAllowsDownloadImageInDataset ? props.t('download_selected_images') : props.t('cant_download_with_annotate_and_go')}
              disabled={!isAnyImageSelected || !props.userPermissions.planAllowsDownloadImageInDataset}
              onClick={props.downloadImages}
            />
          </EnableForRole>
        </div>
        <div className="image-view-control">
          <IconedButton
            color="warning"
            className="image-list-button bulk-delete-button"
            icon={faTrashAlt}
            title={props.t('delete_selected_images')}
            disabled={!isAnyImageSelected}
            onClick={props.toggleConfirmationModal}
          />
        </div>
        <UncontrolledDropdown>
          <DropdownToggle caret color="secondary" id="sorting-dropdown">
            <FontAwesomeIcon icon={faSortAmountDown} />
          </DropdownToggle>
          <DropdownMenu right={true}>
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IDatasetDetailsImage>('name'))}
              className={props.orderBy === nameOf<IDatasetDetailsImage>('name') ? 'selected' : ''}
            >
              {props.t('name')}
            </DropdownItem>
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IDatasetDetailsImage>('createdDate'))}
              className={props.orderBy === nameOf<IDatasetDetailsImage>('createdDate') ? 'selected' : ''}
            >
              {props.t('createdDate')}
            </DropdownItem>
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IDatasetDetailsImage>('width'))}
              className={props.orderBy === nameOf<IDatasetDetailsImage>('width') ? 'selected' : ''}
            >
              {props.t('image_width')}
            </DropdownItem>
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IDatasetDetailsImage>('height'))}
              className={props.orderBy === nameOf<IDatasetDetailsImage>('height') ? 'selected' : ''}
            >
              {props.t('image_height')}
            </DropdownItem>
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IDatasetDetailsImage>('annotationsCount'))}
              className={props.orderBy === nameOf<IDatasetDetailsImage>('annotationsCount') ? 'selected' : ''}
            >
              {props.t('annotated')}
            </DropdownItem>
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IDatasetDetailsImage>('acceptedAnnotationsCount'))}
              className={props.orderBy === nameOf<IDatasetDetailsImage>('acceptedAnnotationsCount') ? 'selected' : ''}
            >
              {props.t('accepted')}
            </DropdownItem>
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderByChange)(nameOf<IDatasetDetailsImage>('size'))}
              className={props.orderBy === nameOf<IDatasetDetailsImage>('size') ? 'selected' : ''}
            >
              {props.t('image_size')}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderDirectionChange)('ASC')}
              className={props.orderDirection.toUpperCase() === 'ASC' ? 'selected' : ''}
            >
              {props.t('ascending')}
            </DropdownItem>
            <DropdownItem
              onClick={handleClickAndPassData(props.onOrderDirectionChange)('DESC')}
              className={props.orderDirection.toUpperCase() === 'DESC' ? 'selected' : ''}
            >
              {props.t('descending')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>}
      <div className="image-view-control">
        <IconedButton
          color="primary"
          className="image-list-button image-list-mode-button"
          icon={props.viewMode === ListViewMode.Tiles ? faList : faTh}
          onClick={handleClickAndPassData(props.onViewModeChange)(props.viewMode === ListViewMode.Tiles ? ListViewMode.List : ListViewMode.Tiles)}
        />
      </div>

      <ConfirmationModal
        confirmationHeader={props.t('delete_images_confirmation')}
        confirmationQuestion={deleteConfirmationBody}
        confirmationQuestionValues={{ selectedImagesCount: props.selectedImagesCount, usedImagesCount: props.usedImagesCount }}
        onCancel={props.toggleConfirmationModal}
        onConfirm={props.onBulkDelete}
        showModal={props.showBulkDeleteConfirmation}
        confirmationYes={props.t('delete')}
      />
    </div>
  );
};

export const DatasetDetailsImageListControls = as<React.StatelessComponent<IDatasetDetailsImageListControlsProps>>(
  withNamespaces('datasets')(DatasetDetailsImageListControlsPure),
);

import * as React from 'react';

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IProjectDetailsBl, ProjectDetailsBlType } from '../../projectDetails/projectDetails.bl';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { as, injectProps } from '../../../helpers/react.helpers';

import { Button } from 'reactstrap';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EnableForRole } from '../../../containers/EnableForRole';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import ImportContainer from './Import.container';
import { PageHeader } from '../../../components/PageHeader';
import { ProjectsListControlsContainer } from './ProjectsListControls.container';
import { ProjectsTabs } from './ProjectsTabs';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import uuid from 'uuid';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps extends ITranslatable {
  currentWorkspaceStore: ICurrentWorkspaceStore;
  projectsDetailsBl: IProjectDetailsBl;
  routerStore: IRouterStore;
}

@injectProps({
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  projectsDetailsBl: ProjectDetailsBlType,
  routerStore: RouterStoreType,
})
class ProjectsHeaderPure extends React.Component<IInjectedProps> {
  @autobind
  async handleCreateProjectClicked() {
    const id = uuid.v4();
    await this.props.projectsDetailsBl.createProjectAsync(id);
    this.props.routerStore.push(Home.Projects.Details.Datasets.withParams({ projectId: id, workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
  }

  render() {
    return (
      <div>
        <PageHeader id="projects-header" headerText={this.props.t('projects_header')} tabNavPresent="with-tab-nav" tabNav={ProjectsTabs}>
          <div className="text-right projects-header-right d-flex align-items-end flex-column">
            <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer]} projectRoles={[]}>
              <DisabledWhenWorkspaceOwnerPolicyExceeded>
                <Button className="create-button" onClick={this.handleCreateProjectClicked} color="primary">
                  {this.props.t('create_new_project')}
                </Button>
              </DisabledWhenWorkspaceOwnerPolicyExceeded>
            </EnableForRole>
            <ImportContainer />
            <div className="advanced-creator-info-box">
              <span>{this.props.t('create_project_description')} </span>
            </div>
            <div className="advanced-creator-info-box">
              <ProjectsListControlsContainer />
            </div>
          </div>
        </PageHeader>
      </div>
    );
  }
}

export const ProjectsHeader = as<React.StatelessComponent>(withNamespaces('project')(ProjectsHeaderPure));

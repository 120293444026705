import { action, observable } from 'mobx';

import { Dictionary } from 'async';
import { injectable } from 'inversify';

export interface IOverlayLoaderStore {
  isOverlayVisible(): boolean;
  isSpinnerVisible(key?: string): boolean;

  enableLoader(buttonId: string): void;
  disableLoader(buttonId: string): void;

  hideOverlay(): void;
}

export const OverlayLoaderStoreType = Symbol('OVERLAY_LOADER_STORE');
@injectable()
export class OverlayLoaderStore implements IOverlayLoaderStore {

  @observable
  spinners: Dictionary<boolean> = {};

  isOverlayVisible() {
    for (const key in this.spinners) {
      if (this.spinners[key]) {
        return true;
      }
    }

    return false;
  }

  isSpinnerVisible(key?: string) {
    return key !== undefined && this.spinners[key];
  }

  @action
  hideOverlay() {
    for (const key in this.spinners) {
      this.disableLoader(key);
    }
  }

  @action
  enableLoader(key: string) {
    this.spinners[key] = true;
  }

  @action
  disableLoader(key: string) {
    this.spinners[key] = false;
  }
}

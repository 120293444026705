export function toLocaleDateTimeString(date: string) {
  return new Date(date).toLocaleString();
}

export function toLocaleDateString(date: string) {
  return date === null ? '' : new Date(date).toLocaleDateString();
}

export function toLocaleTimeString(date: string) {
  return new Date(date).toLocaleTimeString();
}

export function addDaysToDate(date: Date, days: number) {
  date.setDate(date.getDate() + days);
  return date;
}

export function withoutTime(date: Date): Date {
  date.setHours(0, 0, 0, 0);
  return date;
}

export function formatTimeSpan(timeSpan: string) {
  return timeSpan.includes('.') ? timeSpan.substring(0, timeSpan.lastIndexOf('.')) : timeSpan;
}

export function getDateFormat() {
  const now = new Date(2013, 11, 31);
  let str = now.toLocaleDateString();
  str = str.replace('31', 'dd');
  str = str.replace('12', 'MM');
  str = str.replace('2013', 'yyyy');
  return str;
}

export function getCurrentUTCTime(): Date {
  const now = new Date();
  now.setMinutes(now.getMinutes() + now.getTimezoneOffset());

  return now;
}

export function dateToUTC(date: Date) {
  const utcDate = new Date(date);
  utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset());

  return utcDate;
}

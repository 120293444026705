import * as React from 'react';

import { Col, Container, Form, Row } from 'reactstrap';

import { ButtonWithLoader } from '../../../components/ButtonWithLoader';
import { DatasetStatus } from '../../datesets/datasetStatus.model';
import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { IProjectAvailableDatasetDto } from '../projectDetails.models';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { RestrictAccessTo } from '../../../containers/RestrictAccessTo';
import { ValidatedSelectInput } from '../../../components/ValidatedSelectInput';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { handleSelectChange } from '../../../helpers/formHelpers';
import { styleFn } from 'react-select/lib/styles';
import { withNamespaces } from 'react-i18next';

interface IProjectDatasetsFormProps {
  projectId: string;
  workspaceId: string;
  selectedDatasetId?: string;
  datasets: IProjectAvailableDatasetDto[];
  projectHasDraftDatasets: boolean;
  onDatasetAddedAsync(): Promise<void>;
  onSelectedDatasetChanged(datasetId: string): void;
}

class ProjectDatasetsFormPure extends React.Component<IProjectDatasetsFormProps & ITranslatable> {
  noOptions = () => this.props.t('no_dataset_published');

  optionStyle: styleFn = (base, state) => ({
    ...base,
    color: state.data.published ? undefined : '#A5A8B6 !important',
  });

  render() {
    const options = this.props.datasets.map(ddo => ({
      label: `${ddo.name} ${ddo.status !== DatasetStatus.PUBLISHED ? this.props.t('not_published') : ''}`,
      value: ddo.id,
      published: ddo.status === DatasetStatus.PUBLISHED,
    }));
    const selected = this.props.selectedDatasetId ? options.find(u => u.value === this.props.selectedDatasetId) : undefined;

    return (
      <RestrictAccessTo workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer]} projectRoles={[]} inProject={''}>
        <Container fluid>
          <Row>
            <Col>
              <h4>
                {this.props.t('add_an_existing_dataset')}
                <span className={`subheader ${this.props.projectHasDraftDatasets ? 'has-draft' : ''}`}>
                  {this.props.t('dataset_must_be_published.part_1')}
                  <strong>{this.props.t('dataset_must_be_published.part_2')}</strong>
                  {this.props.t('dataset_must_be_published.part_3')}
                </span>
              </h4>
            </Col>
          </Row>
          <Form className="form-inline">
            <DisabledWhenWorkspaceOwnerPolicyExceeded>
              <ValidatedSelectInput
                id="dataset-dropdown"
                name="select"
                value={selected || null}
                placeholder={this.props.t('choose_dataset')}
                onChange={handleSelectChange(this.props.onSelectedDatasetChanged)}
                noOptionsMessage={this.noOptions}
                options={options}
                styles={{
                  option: this.optionStyle,
                  singleValue: this.optionStyle,
                }}
              />
              <ButtonWithLoader color="primary" onClick={this.props.onDatasetAddedAsync} disabled={this.props.selectedDatasetId === undefined} loaderKey="add-dataset-button">
                {this.props.t('add')}
              </ButtonWithLoader>
            </DisabledWhenWorkspaceOwnerPolicyExceeded>
          </Form>
        </Container>
      </RestrictAccessTo>
    );
  }
}

export const ProjectDatasetsForm = as<React.ComponentClass<IProjectDatasetsFormProps>>(withNamespaces('project')(ProjectDatasetsFormPure));

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserService, UserServiceType } from '../modules/user/user.service';
import { as, injectProps } from '../helpers/react.helpers';

import { ITranslatable } from '../helpers/translations.helpers';
import { ProjectRole } from '../models/userRole.model';
import React from 'react';
import { WorkspaceRole } from '../modules/workspaces/workspaces.store';
import { withNamespaces } from 'react-i18next';

interface IOuterProps {
  projectRoles: ProjectRole[];
  workspaceRoles: WorkspaceRole[];
  inProject?: string;
  title?: string;
}

interface IInjectedProps {
  userService: IUserService;
  currentWorkspaceStore: ICurrentWorkspaceStore;
}

@injectProps({
  userService: UserServiceType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
})
class EnableForRolePure extends React.Component<IOuterProps & IInjectedProps & ITranslatable> {
  render() {
    const projectRoles = this.props.projectRoles || [];
    const workspaceRoles = this.props.workspaceRoles || [];

    const hasAccess = this.props.userService.isInRole(this.props.inProject, projectRoles) || this.props.currentWorkspaceStore.isInRole(workspaceRoles);

    return React.Children.map(this.props.children, (child: any) => {
      return React.cloneElement(child, {
        disabled: child.props.disabled || !hasAccess,
        title: hasAccess ? child.props.title : this.props.title || this.props.t('you_do_not_have_permission_to_perform_this_operation'),
      });
    },
    );
  }
}

export const EnableForRole = as<React.ComponentClass<IOuterProps>>(withNamespaces('common', { wait: true })(EnableForRolePure));

import './WorkspaceActionsInfo.scss';

import React, { useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkspaceActionsModal } from './modal/WorkspaceActionsModal';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const WorkspaceRolesInfoPure: React.FC<WithNamespaces> = ({ t }: WithNamespaces) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const toggle = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <p className="workspace-actions-info-icon" onClick={toggle} title={t('team.workspace_actions.tooltip')}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </p>
      <WorkspaceActionsModal show={isModalOpen} toggle={toggle} />
    </>
  );
};

export const WorkspaceActionsInfo = withNamespaces('new')(WorkspaceRolesInfoPure);


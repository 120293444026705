import { inject, injectable } from 'inversify';
import { ProjectStatus } from '../../../../../__legacy__/modules/projects/projects.model';
import { WorkspaceRole } from '../../../../../__legacy__/modules/workspaces/workspaces.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../workspaces/currentWorkspace/CurrentWorkspace.store';

export const ImportAnnotationsPermissionsType = Symbol('IMPORT_ANNOTATIONS_PERMISSIONS');

export interface ImportAnnotationsPermissions {
  canImportAnnotations(projectStatus: ProjectStatus): boolean;
}

@injectable()
export class ImportAnnotationsPermissionsImpl implements ImportAnnotationsPermissions {
  constructor(@inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore) {}

  canImportAnnotations(projectStatus: ProjectStatus) {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return (
      projectStatus === ProjectStatus.Published && [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator].includes(currentWorkspaceRole)
    );
  }
}

import { inject, injectable } from 'inversify';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { WorkspaceRole } from '../workspaces/workspaces.store';
import { AnnotateStage, FreeAccessMode } from './freeAccess.store';

export const FreeAccessPermissionsType = Symbol('FREE_ACCESS_PERMISSIONS');

export interface IFreeAccessPermissions {
  canUseKeyboardShortcuts(): boolean;
  canSubmit(mode: FreeAccessMode): boolean;
  canSave(mode: FreeAccessMode): boolean;
  canDiscard(stage: AnnotateStage): boolean;
  canSaveDraft(stage: AnnotateStage, mode: FreeAccessMode): boolean;
  canReAnnotate(): boolean;
  canChangeMode(): boolean;
  canEditAnnotation(): boolean;
}

@injectable()
export class FreeAccessPermissions implements IFreeAccessPermissions {
  constructor(@inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore) {}

  canUseKeyboardShortcuts() {
    return !(this.currentWorkspaceStore.currentWorkspace?.role === WorkspaceRole.Guest);
  }

  canSubmit(mode: FreeAccessMode): boolean {
    return mode === FreeAccessMode.ANNOTATION || mode === FreeAccessMode.CORRECT;
  }

  canSave(mode: FreeAccessMode): boolean {
    return mode === FreeAccessMode.ANNOTATIONRESET || mode === FreeAccessMode.CORRECT;
  }

  canDiscard(stage: AnnotateStage) {
    return (
      stage !== AnnotateStage.NOTANNOTATED &&
      !!this.currentWorkspaceStore.currentWorkspace &&
      [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(this.currentWorkspaceStore.currentWorkspace.role)
    );
  }

  canSaveDraft(stage: AnnotateStage, mode: FreeAccessMode): boolean {
    return !(stage === AnnotateStage.ANNOTATED || stage === AnnotateStage.REVIEWED || stage === AnnotateStage.REJECTED || mode === FreeAccessMode.ANNOTATIONRESET);
  }

  canReAnnotate(): boolean {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRoles = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator, WorkspaceRole.Assistant];

    return permittedRoles.includes(userRole);
  }

  canEditAnnotation(): boolean {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRoles = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator, WorkspaceRole.Assistant];

    return permittedRoles.includes(userRole);
  }

  canChangeMode(): boolean {
    const userRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    const permittedRoles = [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator, WorkspaceRole.Assistant];

    return permittedRoles.includes(userRole);
  }
}

import * as React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IPreparedFile, IWillAttachmentUploadExceedsLimitDto, UploadStatus } from '../attachmentUploadStore';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import Dropzone from 'react-dropzone';
import { EnableForRole } from '../../../containers/EnableForRole';
import { FileList } from '../../../components/FileList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { PacmanLoader } from 'react-spinners';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

library.add(faUpload);

export interface IAttachmentUploadProps {
  canView: boolean;
  isModalOpen: boolean;
  isProcessingImages: boolean;
  preparedFiles: IPreparedFile[];
  willExceedLimit: IWillAttachmentUploadExceedsLimitDto;
  toggleModal(): void;
  handleFileDropped(files: File[]): void;
  onFileUpload(): void;
}

@observer
class AttachmentUploadPure extends React.Component<IAttachmentUploadProps & ITranslatable> {
  render() {
    const acceptedFiles = this.props.preparedFiles.filter(x => x.status === UploadStatus.Valid);
    const rejectedFiles = this.props.preparedFiles.filter(x => x.status !== UploadStatus.Valid);

    return (
      <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator]} projectRoles={[]}>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <Button
            color="primary"
            className="attachments-button upload"
            disabled={!this.props.canView}
            title={this.props.canView ? '' : this.props.t('you_do_not_have_permission_to_perform_this_operation')}
            onClick={this.props.toggleModal}
          >
            <FontAwesomeIcon icon={faUpload} />
            {this.props.t('upload')}
          </Button>
          <Modal className="modal-width-600" isOpen={this.props.isModalOpen} toggle={this.props.toggleModal}>
            <ModalHeader toggle={this.props.toggleModal}>{this.props.t('upload_attachments')}</ModalHeader>
            <ModalBody>
              <div>
                <p>
                  <strong>{this.props.t('please_select_your_files')}</strong>
                </p>
                <Dropzone
                  className="dropzone-generic"
                  activeClassName="active"
                  multiple={true}
                  onDropRejected={this.props.handleFileDropped}
                  onDropAccepted={this.props.handleFileDropped}
                >
                  <FontAwesomeIcon icon={faUpload} />
                  {this.props.t('drop_your_json_file_here_or_select_it_manually')}
                </Dropzone>
                {this.props.isProcessingImages && (
                  <div className="pacman-loader">
                    <span className="pacman-loader-caption">{this.props.t('processing_your_files')}</span>
                    <PacmanLoader color="#1F2046" />
                  </div>
                )}
                {acceptedFiles.length > 0 && this.props.willExceedLimit.willExceedsAvailableSpace && (
                  <p className="error-feedback">
                    {this.props.t('uploading_selected_file_will_exceed_workspace_owners_total_files_size_limit', { plan: this.props.t(this.props.willExceedLimit.ownerPlan) })}
                  </p>
                )}
                {(rejectedFiles.length > 0 || acceptedFiles.length > 0) && !this.props.isProcessingImages && (
                  <div className="file-list-container">
                    <p className="valid">
                      <FontAwesomeIcon icon="check" /> <strong>{acceptedFiles.length}</strong> {this.props.t('files_ready_to_upload')}
                    </p>
                    <p className="invalid">
                      <FontAwesomeIcon icon="times" /> <strong>{rejectedFiles.length} </strong> {this.props.t('files_invalid')}
                      {rejectedFiles.length > 0 && <span>:</span>}
                    </p>
                    <FileList
                      items={rejectedFiles.map(f => ({
                        name: f.file.name,
                        description: this.props.t(f.status),
                      }))}
                    />
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                className="mr-2"
                disabled={acceptedFiles.length === 0 || this.props.willExceedLimit.willExceedsAvailableSpace}
                color={'primary'}
                onClick={this.props.onFileUpload}
              >
                {this.props.t('upload')}
              </Button>
              <Button color={'primary'} outline onClick={this.props.toggleModal} id="cancel-button">
                {this.props.t('cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
      </EnableForRole>
    );
  }
}

export const AttachmentUpload = as<React.ComponentClass<IAttachmentUploadProps>>(withNamespaces('common', { wait: true })(AttachmentUploadPure));

import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ILoaderState, WithLoaderComponentBase } from '../../../helpers/loader.helpers';
import { IProjectDetailsBl, ProjectDetailsBlType } from '../projectDetails.bl';
import { IProjectOverviewBl, ProjectOverviewBlType } from '../../../../modules/projects/project/overview/projectOverview.bl';
import { IProjectsService, ProjectsServiceType } from '../../projects/projects.service';
import { IRouterStore, RouterStoreType } from '../../../stores/router.store';
import { IUserService, UserServiceType } from '../../user/user.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EnableForRole } from '../../../containers/EnableForRole';
import { Home } from '../../../routes/config/Home';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { ProjectPublishConfirmationModal } from '../components/ProjectPublishConfirmationModal';
import { ProjectPublishStrategy } from '../projectDetails.models';
import React from 'react';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import autobind from 'autobind-decorator';
import { withNamespaces } from 'react-i18next';

interface IInjectedProps {
  projectsService: IProjectsService;
  projectDetailsBl: IProjectDetailsBl;
  projectOverviewBl: IProjectOverviewBl;
  router: IRouterStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  userService: IUserService;
}

interface IPublishButtonContainerProps {
  projectId?: string;
  disabled?: boolean;
  children(childrenProps: IPublishButtonProps): React.ReactNode;
}

export interface IPublishButtonProps {
  disabled?: boolean;
  onClick(): void;
  projectId?: string;
}

interface IState extends ILoaderState {
  showModal: boolean;
  strategy: ProjectPublishStrategy;
}

@injectProps({
  projectsService: ProjectsServiceType,
  projectDetailsBl: ProjectDetailsBlType,
  projectOverviewBl: ProjectOverviewBlType,
  router: RouterStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  userService: UserServiceType,
})
class PublishButtonContainerPure extends WithLoaderComponentBase<IInjectedProps & IPublishButtonContainerProps & ITranslatable, IState> {
  state: IState = {
    showModal: false,
    isLoading: false,
    strategy: ProjectPublishStrategy.DiscardDrafts,
  };

  @autobind
  async handlePublishConfirmAsync() {
    if(this.state.isLoading) return;

    if (this.props.projectId) {
      const passedProjectResult = await this.withLoaderAsync<Boolean>(async (): Promise<boolean> => {
        return await this.props.projectDetailsBl.publishAsync(this.props.projectId!, this.state.strategy === ProjectPublishStrategy.DiscardDrafts);
      }, `publish-button-loader-${this.props.projectId}`);
      if (passedProjectResult) {
        this.setState({ showModal: false });
        await this.props.projectsService.refreshAsync();
      }
    } else {
      const projectFromStoreResult = await this.withLoaderAsync<Boolean>(async (): Promise<boolean> => {
        return await this.props.projectDetailsBl.publishAsync(this.props.projectDetailsBl.store.id!, this.state.strategy === ProjectPublishStrategy.DiscardDrafts);
      }, 'publish-button-loader');
      if (projectFromStoreResult) {
        this.props.router.push(Home.Projects.List.All.withParams({ workspaceId: this.props.currentWorkspaceStore.currentWorkspace!.id }));
      }
    }
    await this.props.userService.getUserInfoAsync(); // to get user role in project
  }

  @autobind
  handlePublishCancel() {
    this.setState({ showModal: false });
  }

  @autobind
  async handleOpenModal() {
    if (!this.props.projectId) {
      const canPublish = await this.props.projectDetailsBl.canPublishAsync();
      if (canPublish) {
        await this.props.projectOverviewBl.getProjectDetailsAnnotatingAsync(this.props.projectDetailsBl.store.id);
        this.setState({ showModal: true });
      }
    } else {
      await this.props.projectOverviewBl.getProjectDetailsAnnotatingAsync(this.props.projectId);
      this.setState({ showModal: true });
    }
  }

  @autobind
  async handleStrategyChange(strategy: ProjectPublishStrategy) {
    this.setState({ strategy });
  }

  render() {
    return (
      <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer]} projectRoles={[]}>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          {this.props.children({
            onClick: this.handleOpenModal,
            disabled: this.props.disabled,
            projectId: this.props.projectId,
          })}
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
        <ProjectPublishConfirmationModal
          showModal={this.state.showModal}
          disablePublishButton={this.props.disabled}
          onConfirm={this.handlePublishConfirmAsync}
          onCancel={this.handlePublishCancel}
          projectId={this.props.projectId}
          draftsCount={this.props.projectOverviewBl.store.draftsCount}
          onStrategyChange={this.handleStrategyChange}
          strategy={this.state.strategy}
          hasImportedAnnotations={this.props.projectDetailsBl.store.hasImportedAnnotations}
        />
      </EnableForRole>
    );
  }
}

export const PublishButtonContainer = as<React.ComponentClass<IPublishButtonContainerProps>>(withNamespaces('project', { wait: true })(PublishButtonContainerPure));

import { ITranslatable } from '../helpers/translations.helpers';
import React from 'react';
import { as } from '../helpers/react.helpers';
import { withNamespaces } from 'react-i18next';

interface IProps {
  feedbacks?: string[];
}

export const Feedback = as<React.ComponentClass<IProps>>(
  withNamespaces()((props: IProps & ITranslatable) => {
    const renderFeedbacks = () => {
      const uniqueFeedbacks = props.feedbacks ? Array.from(new Set(props.feedbacks)) : [];
      return uniqueFeedbacks.map((feedback, i) => {
        return <li key={i}>{props.t(feedback)}</li>;
      });
    };
    return (
      <ul className="custom-invalid-feedback">{renderFeedbacks()}</ul>
    );
  }));

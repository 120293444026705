import { action, observable } from 'mobx';

import detector from 'i18next-browser-languagedetector';
import eattachment from '../locales/en/attachment.json';
import en from '../../common/localization/en.json';
import enannotation from '../locales/en/annotation.json';
import enauth from '../locales/en/auth.json';
import enbilling from '../locales/en/billing.json';
import encommon from '../locales/en/common.json';
import endatasets from '../locales/en/datasets.json';
import endesign from '../../design/localization/en.json';
import enintegrations from '../locales/en/integrations.json';
import enproject from '../locales/en/project.json';
import enuser from '../locales/en/user.json';
import enworkspace from '../locales/en/workspace.json';
import i18n from 'i18next';
import { injectable } from 'inversify';
import plannotation from '../locales/pl/annotation.json';
import plattachment from '../locales/pl/attachment.json';
import plauth from '../locales/pl/auth.json';
import plbilling from '../locales/pl/billing.json';
import plcommon from '../locales/pl/common.json';
import pldatasets from '../locales/pl/datasets.json';
import plintegrations from '../locales/pl/integrations.json';
import plproject from '../locales/pl/project.json';
import pluser from '../locales/pl/user.json';
import plworkspace from '../locales/pl/workspace.json';
import { reactI18nextModule } from 'react-i18next';

const language = localStorage.getItem('i18nextLng') || 'en';

i18n
  .use(detector)
  .use(reactI18nextModule)
  .init({
    ns: ['common', 'project', 'auth', 'datasets', 'user'],
    lng: language,
    fallbackLng: 'en',
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.addResourceBundle('en', 'annotation', enannotation, true, true);
i18n.addResourceBundle('en', 'attachment', eattachment, true, true);
i18n.addResourceBundle('en', 'auth', enauth, true, true);
i18n.addResourceBundle('en', 'billing', enbilling, true, true);
i18n.addResourceBundle('en', 'common', encommon, true, true);
i18n.addResourceBundle('en', 'datasets', endatasets, true, true);
i18n.addResourceBundle('en', 'integrations', enintegrations, true, true);
i18n.addResourceBundle('en', 'project', enproject, true, true);
i18n.addResourceBundle('en', 'user', enuser, true, true);
i18n.addResourceBundle('en', 'workspace', enworkspace, true, true);

i18n.addResourceBundle('pl', 'annotation', plannotation, true, true);
i18n.addResourceBundle('pl', 'attachment', plattachment, true, true);
i18n.addResourceBundle('pl', 'auth', plauth, true, true);
i18n.addResourceBundle('pl', 'billing', plbilling, true, true);
i18n.addResourceBundle('pl', 'common', plcommon, true, true);
i18n.addResourceBundle('pl', 'datasets', pldatasets, true, true);
i18n.addResourceBundle('pl', 'integrations', plintegrations, true, true);
i18n.addResourceBundle('pl', 'project', plproject, true, true);
i18n.addResourceBundle('pl', 'user', pluser, true, true);
i18n.addResourceBundle('pl', 'workspace', plworkspace, true, true);

i18n.addResourceBundle('en', 'new', en, true, true);
i18n.addResourceBundle('en', 'design', endesign, true, true);

export enum LanguagesEnum {
  EN = 'en',
  PL = 'pl',
}

export interface ILocalizationStore {
  changeLanguage(lang: LanguagesEnum): void;
}

export const LocalizationStoreType = Symbol('LOCALIZATION_STORE');

@injectable()
export class LocalizationStore implements ILocalizationStore {
  @observable
  i18n = i18n;
  @action
  changeLanguage(lang: LanguagesEnum) {
    this.i18n.changeLanguage(lang);
  }
}

import * as React from 'react';

import { AnnotationUiStoreType, IAnnotationUiStore } from '../annotationUi.store';
import { AnnotationsStoreType, IAnnotationsStore } from '../annotations.store';
import { ITimerService, TimerServiceType } from '../../../services/timer.service';
import { IUndoRedoHistory, UndoRedoHistoryType } from '../undoRedoHistory.service';
import { as, injectProps } from '../../../helpers/react.helpers';

import { IImageComponentProps } from '../components/Image';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IInjectedProps {
  annotationUiStore: IAnnotationUiStore;
  undoRedoHistory: IUndoRedoHistory;
  annotationsStore: IAnnotationsStore;
  timer: ITimerService;
}

@injectProps({
  annotationUiStore: AnnotationUiStoreType,
  undoRedoHistory: UndoRedoHistoryType,
  annotationsStore: AnnotationsStoreType,
  timer: TimerServiceType,
})
@observer
class ImageContainer extends React.Component<IInjectedProps> {
  constructor(props: IInjectedProps) {
    super(props);
    props.annotationUiStore.changeIsImageLoading(true);
  }

  @autobind
  handleZoomLevelChange(zoom: number) {
    if (this.props.annotationsStore.image) this.props.annotationUiStore.changeZoomLevel(zoom);
  }

  @autobind
  async handleLowResImageLoaded() {
    await setTimeout(() => this.props.annotationUiStore.changeIsImageLoading(false), 0);
    this.props.timer.startTimer();
    this.props.undoRedoHistory.clearHistory();
  }

  render() {
    const image = this.props.annotationsStore.image;
    const isLoading = this.props.annotationUiStore.isImageLoading || (image === undefined && this.props.annotationUiStore.isQueueRequestInProgress);

    return React.Children.map(this.props.children, (child: any) =>
      React.cloneElement(child, {
        isLoading,
        isHighResImageLoading: image !== undefined && image.url === '',
        url: image?.url ?? '',
        lowQualityUrl: image?.lowQualityUrl ?? '',
        height: image?.height ?? 0,
        width: image?.width ?? 0,
        isLeftMenuOpened: this.props.annotationUiStore.isLeftMenuOpened,
        zoomLevel: this.props.annotationUiStore.zoomLevel,
        minZoomLevel: this.props.annotationUiStore.minZoomLevel,
        maxZoomLevel: this.props.annotationUiStore.maxZoomLevel,
        onZoomChanged: this.handleZoomLevelChange,
        onLowResImageLoaded: this.handleLowResImageLoaded,
      } as IImageComponentProps),
    );
  }
}

export default as<React.ComponentClass>(ImageContainer);

import { ClipLoader } from 'react-spinners';
import React from 'react';

interface ILoaderProps {
  isLoading: boolean;
  size?: number;
  solid?: boolean;
  hideSpinner?: boolean;
}

export class Loader extends React.PureComponent<ILoaderProps> {
  render() {
    return (
      <div className="loader-header">
        <div className={this.props.isLoading ? `loading ${this.props.solid ? 'solid' : ''}` : 'loaded'}>
          {!this.props.hideSpinner && <ClipLoader loading={this.props.isLoading} size={this.props.size ? this.props.size : 70} />}
        </div>
        {this.props.children}
      </div>
    );
  }
}

import { observable, set } from 'mobx';

import React from 'react';
import autobind from 'autobind-decorator';
import { nameof } from './nameof';
import { pageSizeOptions } from '../../design/pagination/paginationPageSize/S_PaginationPageSizeOptions';

export interface ISettingsStore {
  attachmentsPageSize: number;
  datasetDetailsImagesPageSize: number;
  datasetDetailsImagesThumbnailsPageSize: number;
  datasetDetailsProjectsPageSize: number;
  datasetsPageSize: number;
  usersPageSize: number;
  activityLogPageSize: number;
  projectDetailsImagesPageSize: number;
  statisticsPageSize: number;
  projectsPageSize: number;
  projectsCardsPageSize: number;
  teamPageSize: number;
  workspacesPageSize: number;
  projectsExportsPageSize: number;
  adminPanelPageSize: number;
}

export interface ISettingsContext {
  store: ISettingsStore;
  setAttachmentsPageSize(pageSize: number): void;
  setDatasetDetailsImagesPageSize(pageSize: number): void;
  setDatasetDetailsProjectsPageSize(pageSize: number): void;
  setDatasetsPageSize(pageSize: number): void;
  setUsersPageSize(pageSize: number): void;
  setActivityLogPageSize(pageSize: number): void;
  setProjectDetailsImagesPageSize(pageSize: number): void;
  setStatisticsPageSize(pageSize: number): void;
  setProjectsPageSize(pageSize: number): void;
  setProjectsCardsPageSize(pageSize: number): void;
  setTeamPageSize(pageSize: number): void;
  setWorkspacesPageSize(pageSize: number): void;
  setProjectsExportsPageSize(pageSize: number): void;
  setAdminPanelPageSize(pageSize: number): void;
}

class SettingsContext implements ISettingsContext {
  public store: ISettingsStore;
  private readonly SETTINGS_KEY = 'settings';

  constructor() {
    const defaultPageSize = pageSizeOptions[0];
    const settingsString = localStorage.getItem(this.SETTINGS_KEY);
    const settings = settingsString ? JSON.parse(settingsString) : {};

    this.store = observable({
      attachmentsPageSize: defaultPageSize,
      datasetDetailsImagesPageSize: defaultPageSize,
      datasetDetailsImagesThumbnailsPageSize: defaultPageSize,
      datasetDetailsProjectsPageSize: defaultPageSize,
      datasetsPageSize: defaultPageSize,
      usersPageSize: defaultPageSize,
      activityLogPageSize: defaultPageSize,
      projectDetailsImagesPageSize: defaultPageSize,
      statisticsPageSize: defaultPageSize,
      projectsPageSize: defaultPageSize,
      projectsCardsPageSize: defaultPageSize,
      teamPageSize: defaultPageSize,
      workspacesPageSize: defaultPageSize,
      projectsExportsPageSize: defaultPageSize,
      adminPanelPageSize: defaultPageSize,
      ...settings,
    });
  }

  @autobind
  setAttachmentsPageSize(pageSize: number) {
    set(this.store, { attachmentsPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('attachmentsPageSize'), pageSize);
  }

  @autobind
  setDatasetDetailsImagesPageSize(pageSize: number) {
    set(this.store, { datasetDetailsImagesPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('datasetDetailsImagesPageSize'), pageSize);
  }

  @autobind
  setDatasetDetailsImagesThumbnailsPageSize(pageSize: number) {
    set(this.store, { datasetDetailsImagesThumbnailsPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('datasetDetailsImagesThumbnailsPageSize'), pageSize);
  }

  @autobind
  setDatasetDetailsProjectsPageSize(pageSize: number) {
    set(this.store, { datasetDetailsProjectsPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('datasetDetailsProjectsPageSize'), pageSize);
  }

  @autobind
  setDatasetsPageSize(pageSize: number) {
    set(this.store, { datasetsPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('datasetsPageSize'), pageSize);
  }

  @autobind
  setUsersPageSize(pageSize: number) {
    set(this.store, { usersPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('usersPageSize'), pageSize);
  }

  @autobind
  setActivityLogPageSize(pageSize: number) {
    set(this.store, { activityLogPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('activityLogPageSize'), pageSize);
  }

  @autobind
  setProjectDetailsImagesPageSize(pageSize: number) {
    set(this.store, { projectDetailsImagesPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('projectDetailsImagesPageSize'), pageSize);
  }

  @autobind
  setStatisticsPageSize(pageSize: number) {
    set(this.store, { statisticsPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('statisticsPageSize'), pageSize);
  }

  @autobind
  setProjectsPageSize(pageSize: number) {
    set(this.store, { projectsPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('projectsPageSize'), pageSize);
  }

  @autobind
  setProjectsCardsPageSize(pageSize: number) {
    set(this.store, { projectsCardsPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('projectsCardsPageSize'), pageSize);
  }

  @autobind
  setTeamPageSize(pageSize: number) {
    set(this.store, { teamPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('teamPageSize'), pageSize);
  }

  @autobind
  setWorkspacesPageSize(pageSize: number) {
    set(this.store, { workspacesPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('workspacesPageSize'), pageSize);
  }

  @autobind
  setProjectsExportsPageSize(pageSize: number) {
    set(this.store, { projectsExportsPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('projectsExportsPageSize'), pageSize);
  }

  @autobind
  setAdminPanelPageSize(pageSize: number): void {
    set(this.store, { adminPanelPageSize: pageSize });
    this.storeValue(nameof<ISettingsStore>('adminPanelPageSize'), pageSize);
  }

  @autobind
  private storeValue(settingName: string, settingValue: any) {
    set(this.store, { [settingName]: settingValue });
    const settingsString = localStorage.getItem(this.SETTINGS_KEY);
    const settings = settingsString ? JSON.parse(settingsString) : {};

    settings[settingName] = settingValue;
    localStorage.setItem(this.SETTINGS_KEY, JSON.stringify(settings));
  }
}

const defaultSettingsContext = new SettingsContext();

export const Settings = React.createContext<SettingsContext>(defaultSettingsContext);

export class SettingsContextProvider extends React.Component<{}, { ctx: SettingsContext }> {
  state = { ctx: defaultSettingsContext };

  render() {
    return <Settings.Provider value={this.state.ctx}>{this.props.children}</Settings.Provider>;
  }
}

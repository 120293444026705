import { inject, injectable } from 'inversify';
import { ICurrentWorkspaceStore, CurrentWorkspaceStoreType } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';

import { WorkspaceRole } from '../workspaces/workspaces.store';

export const TeamPermissionsType = Symbol('TEAM_PERMISSIONS');

export interface ITeamPermissions {
  canSeeOwnerErrors(): boolean;
  canViewTeamMembers(): boolean;
  canManageTeamMembers(): boolean;
}

@injectable()
export class TeamPermissions implements ITeamPermissions {
  constructor(@inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore) {}

  canSeeOwnerErrors() {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner].includes(currentWorkspaceRole);
  }

  canViewTeamMembers() {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator].includes(currentWorkspaceRole);
  }

  canManageTeamMembers() {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(currentWorkspaceRole);
  }
}

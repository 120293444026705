import './SideNav.scss';

import * as React from 'react';

import { WithNamespaces, withNamespaces } from 'react-i18next';
import { faBook, faCog, faDatabase, faFolderOpen, faPaperclip, faPuzzlePiece, faUserCog } from '@fortawesome/free-solid-svg-icons';

import { EnableForRole } from '../containers/EnableForRole';
import { ExternalLinks } from '../routes/config/ExternalLinks';
import { Home } from '../routes/config/Home';
import { Link } from 'react-router-dom';
import { LogoSignet } from './StaticImages';
import { SideNavMenuExternalItem } from './SideNavMenuIExternaltem';
import { SideNavMenuItem } from './SideNavMenuItem';
import { UserRole } from '../modules/user/user.store';
import { WorkspaceRole } from '../modules/workspaces/workspaces.store';
import { alertBarHeight } from '../../modules/alertBar/ui/AlertBar';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faBook, faFolderOpen, faDatabase, faCog, faPuzzlePiece, faUserCog, faPaperclip);

export interface ISideNavProps {
  userRole: UserRole;
  userLinkExtension: string;
  workspaceRole?: WorkspaceRole;
  workspaceId: string;
  alertsCount: number;
  beforeAttachmentClick(): void;
}

const SideNavPure: React.FC<ISideNavProps & WithNamespaces> = (props: ISideNavProps & WithNamespaces) => (
  <div className="side-nav" style={{ top: `${alertBarHeight(props.alertsCount)}px`, height: `calc(100vh - ${alertBarHeight(props.alertsCount)}px)` }}>
    <div className="logo">
      <Link to={Home.Projects.List.All.withParams({ workspaceId: props.workspaceId })}>
        <img src={LogoSignet} alt="Logo" />
      </Link>
    </div>
    <ul className="nav-list list-unstyled">
      <div>
        <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer]} projectRoles={[]}>
          <SideNavMenuItem
            id="side-nav-team"
            name={props.t('team')}
            icon="users"
            link={Home.Team.List.withParams({ workspaceId: props.workspaceId })}
            highlightLink={Home.Team.Path}
            disabled={false}
          />
        </EnableForRole>
        <SideNavMenuItem
          id="side-nav-datasets"
          name={props.t('datasets')}
          icon="database"
          link={Home.Datasets.List.withParams({ workspaceId: props.workspaceId })}
          highlightLink={Home.Datasets.Path}
          disabled={props.workspaceRole === WorkspaceRole.Worker}
        />
        <SideNavMenuItem
          id="side-nav-projects"
          name={props.t('projects')}
          icon="folder-open"
          link={Home.Projects.List.All.withParams({ workspaceId: props.workspaceId })}
          highlightLink={Home.Projects.Path}
          disabled={false}
        />
        <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer]} projectRoles={[]}>
          <SideNavMenuItem
            id="side-nav-attachments"
            name={props.t('attachments')}
            icon="paperclip"
            link={Home.Attachments.List.Folder.withParams({ workspaceId: props.workspaceId, attachmentId: '' })}
            highlightLink={Home.Attachments.Index}
            disabled={false}
            beforeClick={props.beforeAttachmentClick}
          />
        </EnableForRole>
      </div>
      <div className="bottom-part">
        {props.userRole === UserRole.Administrator && (
          <SideNavMenuItem
            id="side-nav-admin"
            name={props.t('admin_panel')}
            icon="user-cog"
            link={Home.Admin.Users.withParams({ workspaceId: props.workspaceId })}
            highlightLink={Home.Admin.Path}
            disabled={false}
          />
        )}
        <SideNavMenuItem
          id="side-nav-api"
          name={props.t('api')}
          icon="puzzle-piece"
          link={Home.Integrations.List.All.withParams({ workspaceId: props.workspaceId })}
          highlightLink={Home.Integrations.Path}
          disabled={false}
        />
        <SideNavMenuExternalItem id="side-nav-tutorials" name={props.t('tutorials')} icon="book" link={`${ExternalLinks.ZillinIo.Tutorials.Path}${props.userLinkExtension}`} />
      </div>
    </ul>
  </div>
);

export const SideNav = withNamespaces('common')(SideNavPure);

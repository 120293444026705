export const PencilWithRuler = require('../static/images/pencil_with_ruler.png');

export const LogoWhiteText = require('../static/images/logo_full_white_text.png');

export const LogoSignet = require('../static/images/logo_signet.png');

export const PreviewDefaultImage = require('../static/images/preview-min.jpg');

export const ArrowGradient = require('../static/images/svg_arrow_gradient.png');

export const CheckFilledGradient = require('../static/images/svg_check_filled_gradient.png');

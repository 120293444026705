import { inject, injectable } from 'inversify';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { ProjectStatus } from '../projects/projects.model';
import { WorkspaceRole } from '../workspaces/workspaces.store';

export const ProjectDetailsPermissionsType = Symbol('PROJECT_DETAILS_PERMISSIONS');

export interface IProjectDetailsPermissions {
  canEditAnnotationTypeColor(): boolean;
  canEditProject(projectStatus: ProjectStatus): boolean;
  canUpdateImageSet(): boolean;
  canSeeAnnotationAuthors(): boolean;
  canBatchAnswer(): boolean;
  canRemoveUserFromProject(): boolean;
  canUnlinkDataset(): boolean;
  canViewDataset(): boolean;
  canEditDatasetOrder(): boolean
  canUpdateImageAssignmentPolicy(): boolean
}

@injectable()
export class ProjectDetailsPermissions implements IProjectDetailsPermissions {
  constructor(
    @inject(CurrentWorkspaceStoreType) private readonly currentWorkspaceStore: ICurrentWorkspaceStore
  ) {
  }

  canEditProject(projectStatus: ProjectStatus) {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return projectStatus === ProjectStatus.Published
      ? [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(currentWorkspaceRole)
      : [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer].includes(currentWorkspaceRole);
  }

  canEditAnnotationTypeColor() {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer].includes(currentWorkspaceRole);
  }

  canUpdateImageSet(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer].includes(currentWorkspaceRole);
  }

  canSeeAnnotationAuthors(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer].includes(currentWorkspaceRole);
  }

  canBatchAnswer(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer].includes(currentWorkspaceRole);
  }

  canRemoveUserFromProject(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer].includes(currentWorkspaceRole);
  }
  canUnlinkDataset(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer].includes(currentWorkspaceRole);
  }

  canViewDataset(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator].includes(currentWorkspaceRole);
  }

  canEditDatasetOrder(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(currentWorkspaceRole);
  }

  canUpdateImageAssignmentPolicy(): boolean {
    const currentWorkspaceRole = this.currentWorkspaceStore.currentWorkspace?.role || WorkspaceRole.Worker;
    return [WorkspaceRole.Owner, WorkspaceRole.Manager].includes(currentWorkspaceRole);
  }
}

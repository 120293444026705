import * as React from 'react';

import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { GTable, IGColumnProps } from '../../../components/table/GTable';
import { faEdit, faGlobe, faSync, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EnableForRole } from '../../../containers/EnableForRole';
import { Home } from '../../../routes/config/Home';
import { IDatasetListItemViewModel } from '../datasets.store';
import { IPagingInfoWithOrder } from '../../../models/paginationInfo.model';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { IconedButton } from '../../../components/IconedButton';
import { IconedLinkButton } from '../../../containers/IconedLinkButton';
import { Link } from 'react-router-dom';
import { LinkedButton } from '../../../components/LinkedButton';
import { Loader } from '../../../components/Loader';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import { compact } from 'lodash';
import { handleClickAndPassData } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { toLocaleDateTimeString } from '../../../helpers/date.helpers';
import { withNamespaces } from 'react-i18next';

library.add(faTrashAlt, faEdit, faSync, faGlobe);

export interface IDatasetsListComponentProps {
  datasetsList: IDatasetListItemViewModel[];
  isLoading: boolean;
  workspaceId: string;
  datasetsPaging: IPagingInfoWithOrder;
  onShowDatasetDeleteConfirmationModal(datasetId: string): void;
  onDeleteDataset(datasetId: string): void;
  onOrderingChange(orderBy: string, orderType: string): void;
  onPaginationChange(pageNumber: number, pageSize: number): void;
  canSeeDatasetCreatedBy: boolean;
}

class DatasetsListPure extends React.Component<IDatasetsListComponentProps & ITranslatable> {
  get columns(): IGColumnProps<IDatasetListItemViewModel>[] {
    return compact([
      {
        field: 'name',
        renderer: this.renderName,
        headerName: this.props.t('name'),
        sortable: true,
        minWidth: 300,
      },
      this.props.canSeeDatasetCreatedBy && {
        field: 'createdBy',
        renderer: this.renderCreatedBy,
        headerName: this.props.t('created_by'),
        sortable: true,
        width: 300,
      },
      {
        field: 'status',
        headerName: this.props.t('status'),
        sortable: true,
        width: 120,
      },
      {
        field: 'projectsCount',
        headerName: this.props.t('number_of_projects'),
        sortable: true,
        width: 120,
      },
      {
        field: 'imagesCount',
        headerName: this.props.t('number_of_images_short'),
        sortable: true,
        width: 120,
      },
      {
        field: 'imagesSize',
        headerName: this.props.t('size_of_images'),
        renderer: this.renderSize,
        sortable: true,
        width: 120,
      },
      {
        field: 'createdDate',
        headerName: this.props.t('created'),
        sortable: true,
        renderer: this.renderCreatedDate,
        width: 180,
      },
      {
        field: 'lastModified',
        headerName: this.props.t('last_modified_date'),
        sortable: true,
        renderer: this.renderModifiedDate,
        width: 180,
      },
      {
        headerName: this.props.t('actions'),
        field: '',
        renderer: this.renderActions,
        sortable: false,
        width: 120,
      },
    ]);
  }

  renderSize = (item: IDatasetListItemViewModel) => {
    const result = item.imagesSize.toFixed(2);
    return <>{result}</>;
  };

  renderActions = (item: IDatasetListItemViewModel) => {
    const { t } = this.props;
    const disabled = item.projectsCount > 0;
    return (
      <div className="g-actions">
        <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator]} projectRoles={[]}>
          <IconedLinkButton
            icon={'edit'}
            to={Home.Datasets.Details.Images.withParams({ datasetId: item.id, workspaceId: this.props.workspaceId, page: 1 })}
            title={this.props.t('edit_dataset')}
          />
          <IconedButton
            onClick={handleClickAndPassData(this.props.onShowDatasetDeleteConfirmationModal)(item.id)}
            title={disabled ? t('delete_error_dataset_assigned_to_project') : t('delete_dataset_tooltip')}
            icon={'trash-alt'}
            color="red"
            disabled={disabled}
          />
        </EnableForRole>
      </div>
    );
  };

  renderName = (item: IDatasetListItemViewModel) => {
    const title = `${item.name}\n${item.description}`;

    return (
      <div className="ellipsisAndHidden" title={title}>
        <Link className="text-link-in-table" to={Home.Datasets.Details.Images.withParams({ datasetId: item.id, workspaceId: this.props.workspaceId, page: 1 })}>
          {item.name}
        </Link>
      </div>
    );
  };

  renderCreatedBy = (item: IDatasetListItemViewModel) => {
    const createdBy = item.createdBy;
    return <span title={createdBy}>{createdBy}</span>;
  };

  renderCreatedDate = (item: IDatasetListItemViewModel) => <>{toLocaleDateTimeString(item.createdDate)}</>;

  renderModifiedDate = (item: IDatasetListItemViewModel) => <>{toLocaleDateTimeString(item.lastModified)}</>;

  render() {
    return (
      <Loader isLoading={this.props.isLoading}>
        {this.props.datasetsList.length !== 0 ? (
          <div className="table-container">
            <GTable<IDatasetListItemViewModel>
              columns={this.columns}
              items={this.props.datasetsList}
              paginationProps={{
                pageNumber: this.props.datasetsPaging.pageNumber,
                pageSize: this.props.datasetsPaging.pageSize,
                totalCount: this.props.datasetsPaging.totalCount,
                onChange: this.props.onPaginationChange,
              }}
              onSortChanged={this.props.onOrderingChange}
              sortingModel={[
                {
                  orderBy: this.props.datasetsPaging.orderBy || '',
                  orderType: this.props.datasetsPaging.orderType || '',
                },
              ]}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-3">
              <Card className="project-card create">
                <CardBody>
                  <CardTitle tag="h5">{this.props.t('create_new_dataset')}</CardTitle>
                  <CardText>{this.props.t('you_dont_have_any_datasets')}</CardText>
                  <DisabledWhenWorkspaceOwnerPolicyExceeded>
                    <LinkedButton
                      id="create-new-dataset"
                      color="primary"
                      text={this.props.t('create_new_dataset')}
                      to={Home.Datasets.Create.withParams({ datasetDraftId: '', workspaceId: this.props.workspaceId })}
                    />
                  </DisabledWhenWorkspaceOwnerPolicyExceeded>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </Loader>
    );
  }
}

export const DatasetsList = as<React.FunctionComponent<IDatasetsListComponentProps>>(withNamespaces('datasets', { wait: true })(DatasetsListPure));

import * as React from 'react';

import { ExternalLinks } from '../../../routes/config/ExternalLinks';
import { LogoWhiteText } from '../../../components/StaticImages';

export const AuthLogo = () => {
  return (
    <div className="auth-logo">
      <a href={ExternalLinks.ZillinSitePath} target="_blank" rel="noopener noreferrer">
        <img src={LogoWhiteText} alt="Logo" />
      </a>
    </div>
  );
};

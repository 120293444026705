import * as React from 'react';

import { Col, Container, Row } from 'reactstrap';

type tabNavPresentState = 'with-tab-nav' | 'clean';

interface IProps {
  id?: string;
  headerText: string;
  headerNumber?: number;
  headerDescription?: string | React.ReactElement<any>;
  tabNavPresent: tabNavPresentState;
  tabNav?: React.StatelessComponent;
  children?: React.ReactElement<any>[] | React.ReactElement<any>;
  headerAuthor?: string;
  headerTermsOfUse?: string;
}

export const PageHeader = (props: IProps) => (
  <Container fluid className={`${props.tabNavPresent} page-header`}>
    <Row>
      <Col sm={props.children ? '8' : '12'}>
        <h1 id={props.id}>
          {props.headerText}
          {props.headerNumber !== undefined && props.headerNumber >= 0 &&
            <span className="count">({props.headerNumber})</span>}
        </h1>
        {props.headerDescription && <p>{props.headerDescription}</p>}
        {props.headerAuthor && <p>{props.headerAuthor}</p>}
        {props.headerTermsOfUse && <p>{props.headerTermsOfUse}</p>}
      </Col>
      {props.children && <Col sm="4">{props.children}</Col>}
    </Row>
    <Row>
      <Col>{props.tabNav && <props.tabNav />}</Col>
    </Row>
  </Container>
);

import { AlertBarStoreType, IAlertBarStore } from '../../../../modules/alertBar/AlertBar.store';
import { AnnotateStage, FreeAccessMode, FreeAccessStoreType, IFreeAccessStore } from '../freeAccess.store';
import { Col, Container, Row } from 'reactstrap';
import { FreeAccessService, FreeAccessServiceType } from '../freeAccess.service';
import { RouteComponentProps, withRouter } from 'react-router';
import { as, injectProps } from '../../../helpers/react.helpers';

import AnnotationImageNameHeaderContainer from '../../annotation/containers/AnnotationImageNameHeader.container';
import { AnnotationMoreOptions } from '../../annotation/components/AnnotationMoreOptions';
import { AnnotationTopBarItem } from '../../annotation/components/AnnotationTopBarItem';
import { AnnotationTypesContainer } from '../../annotation/submodules/annotationTypes/containers/AnnotationTypes.container';
import { AnnotationViewOptions } from '../../annotation/components/AnnotationViewOptions';
import { BackToProjectDetailsButton } from '../components/backToProjectDetailsButton';
import { CurrentWorkspaceContainer } from '../../workspaces/containers/CurrentWorkspace.container';
import { FreeAccessActionsContainer } from './freeAccessActions.container';
import { FreeAccessAnnotateControlsContainer } from './freeAccessAnnotateControls.container';
import { FreeAccessImageNavigation } from '../components/FreeAccessImageNavigation';
import { FreeAccessImageSetupContainer } from './FreeAccessImageSetup.container';
import { FreeAccessNavigationContainer } from './freeAccessNavigation.container';
import { FreeAccessReviewControlsContainer } from './freeAccessReviewControls.container';
import { FreeAccessReviewControlsCorrectContainer } from './freeAccessReviewCorrectControls.container';
import { ImageSegmentationsContextProvider } from '../../annotation/submodules/segmentations/segmentationContextMenu/imageSegmentations.context';
import { LeftMenuContainer } from '../../annotation/submodules/segmentations/containers/LeftMenu.container';
import { NoAnnotationTypesWarningContainer } from '../../annotation/containers/NoAnnotationTypesWarningContainer';
import QuestionsContainer from '../../annotation/containers/Questions.container';
import QuestionsList from '../../annotation/components/annotations/QuestionsList';
import React from 'react';
import { RestrictAccessTo } from '../../../containers/RestrictAccessTo';
import ReturnToToolsContainer from '../../annotation/containers/ReturnToTools.container';
import SegmentationsSaveContainer from '../../annotation/containers/SegmentationsSave.container';
import { UndoRedoAnnotationsShortcutsContainer } from '../../annotation/containers/UndoRedoAnnotationsShortcuts.container';
import { UnlockWorkspaceModal } from '../../workspaces/components/UnlockWorkspaceModal';
import ViewAttributesButton from '../../annotation/components/ViewAttributesButton';
import ViewAttributesContainer from '../../annotation/containers/ViewAttributesContainer';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { alertBarHeight } from '../../../../modules/alertBar/ui/AlertBar';
import autobind from 'autobind-decorator';
import { compact } from 'lodash/fp';
import { observer } from 'mobx-react';

interface IInjectedProps extends RouteComponentProps<{ workspaceId: string; projectId: string; imageId: string; filterId: string }> {
  freeAccessStore: IFreeAccessStore;
  freeAccessService: FreeAccessService;
  alertBarStore: IAlertBarStore;
}

@injectProps({
  freeAccessStore: FreeAccessStoreType,
  freeAccessService: FreeAccessServiceType,
  alertBarStore: AlertBarStoreType,
})
@observer
class FreeAccessContainerPure extends React.Component<IInjectedProps> {
  componentDidMount() {
    this.props.freeAccessStore.setView(FreeAccessMode.VIEW, AnnotateStage.NOTANNOTATED);
    this.props.freeAccessStore.imageId = '';
  }

  @autobind
  async unlockImages() {
    if (this.props.freeAccessStore.freeAccessMode !== FreeAccessMode.VIEW) {
      await this.props.freeAccessService.unlockImagesAsync();
    }
    this.props.freeAccessService.imagesQueueService.clear();
  }

  componentWillUnmount() {
    this.unlockImages();
  }

  getAvailableShortcuts() {
    const stage = this.props.freeAccessStore.annotateStage;

    return compact([
      stage !== AnnotateStage.NOTANNOTATED && { label: 'Edit', keys: ['e'] },
      { label: 'Annotate', keys: ['a'] },
      [AnnotateStage.ANNOTATED, AnnotateStage.REVIEWED].includes(stage) && { label: 'Review', keys: ['r'] },
    ]);
  }

  render() {
    const mode = this.props.freeAccessStore.freeAccessMode;
    const isReadOnly = mode === FreeAccessMode.VIEW || mode === FreeAccessMode.REVIEW;

    return (
      <div className="annotation-view free-access">
        <CurrentWorkspaceContainer>{unlockModalProps => <UnlockWorkspaceModal {...unlockModalProps} />}</CurrentWorkspaceContainer>
        <ImageSegmentationsContextProvider imageId={this.props.match.params.imageId}>
          <div className="annotation-view-top-bar" style={{ marginTop: alertBarHeight(this.props.alertBarStore.alerts.length) }}>
            <FreeAccessNavigationContainer
              workspaceId={this.props.match.params.workspaceId}
              projectId={this.props.match.params.projectId}
              filterId={this.props.match.params.filterId}
              callback={this.unlockImages}
            >
              {(freeNav, backNav) => (
                <Container fluid>
                  <AnnotationTopBarItem variation="labeled-icon">
                    <BackToProjectDetailsButton {...backNav} />
                  </AnnotationTopBarItem>
                  {!isReadOnly && (
                    <>
                      <AnnotationTopBarItem>
                        <UndoRedoAnnotationsShortcutsContainer undo={true} />
                      </AnnotationTopBarItem>
                      <AnnotationTopBarItem>
                        <UndoRedoAnnotationsShortcutsContainer undo={false} />
                      </AnnotationTopBarItem>
                    </>
                  )}
                  <AnnotationTopBarItem variation="labeled-icon">
                    <AnnotationViewOptions disableCursorConfig={[FreeAccessMode.VIEW, FreeAccessMode.REVIEW].includes(mode)} />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="show-attributes">
                    <ViewAttributesContainer>
                      <ViewAttributesButton />
                    </ViewAttributesContainer>
                  </AnnotationTopBarItem>
                  <RestrictAccessTo
                    projectRoles={[]}
                    workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator, WorkspaceRole.Guest, WorkspaceRole.Assistant]}
                    inProject={this.props.freeAccessStore.projectId}
                  >
                    <AnnotationTopBarItem variation="image-name">
                      <AnnotationImageNameHeaderContainer />
                    </AnnotationTopBarItem>
                  </RestrictAccessTo>
                  <AnnotationTopBarItem variation="image-navigation-item">
                    <FreeAccessImageNavigation {...freeNav} />
                  </AnnotationTopBarItem>
                  <AnnotationTopBarItem variation="more">
                    <AnnotationMoreOptions
                      isFreeAccess={true}
                      create={mode === FreeAccessMode.ANNOTATION || mode === FreeAccessMode.ANNOTATIONRESET || mode === FreeAccessMode.CORRECT}
                      review={mode === FreeAccessMode.REVIEW}
                      correct={mode === FreeAccessMode.REVIEWCORRECT}
                      edit={mode === FreeAccessMode.ANNOTATIONRESET}
                      globalShortcuts={mode === FreeAccessMode.VIEW ? this.getAvailableShortcuts() : []}
                    />
                  </AnnotationTopBarItem>
                </Container>
              )}
            </FreeAccessNavigationContainer>
          </div>
          <div
            className="annotation-view-inner"
            style={{
              marginTop: alertBarHeight(this.props.alertBarStore.alerts.length) + 70,
              height: `calc(100vh - ${70 + alertBarHeight(this.props.alertBarStore.alerts.length)}px)`,
            }}
          >
            <Container fluid>
              <Row>
                <LeftMenuContainer currentProjectId={this.props.match.params.projectId} isReadOnly={isReadOnly} showObjectsList={true} isReview={false} />
                <Col className="px-0">
                  <FreeAccessImageSetupContainer
                    isReadOnly={isReadOnly}
                    imageId={this.props.match.params.imageId}
                    projectId={this.props.match.params.projectId}
                    workspaceId={this.props.match.params.workspaceId}
                    filterId={this.props.match.params.filterId}
                  />
                </Col>
                <div className="right-column" style={{ height: `calc(100vh - ${70 + alertBarHeight(this.props.alertBarStore.alerts.length)}px)` }}>
                  <div className="set-navigation-container">
                    <FreeAccessActionsContainer />
                  </div>
                  <div className="scrollable-container">
                    <ReturnToToolsContainer readonly={isReadOnly} />
                    <div className="scrollable-container-content">
                      <NoAnnotationTypesWarningContainer />
                      <AnnotationTypesContainer readonly={isReadOnly} />
                      <QuestionsContainer readonly={isReadOnly}>
                        <QuestionsList />
                      </QuestionsContainer>
                      {!isReadOnly && <SegmentationsSaveContainer />}
                    </div>
                  </div>
                  <div className="set-navigation-container">
                    {(mode === FreeAccessMode.ANNOTATION || mode === FreeAccessMode.ANNOTATIONRESET || mode === FreeAccessMode.CORRECT) && <FreeAccessAnnotateControlsContainer />}
                    {mode === FreeAccessMode.REVIEW && <FreeAccessReviewControlsContainer />}
                    {mode === FreeAccessMode.REVIEWCORRECT && <FreeAccessReviewControlsCorrectContainer />}
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </ImageSegmentationsContextProvider>
      </div>
    );
  }
}

export const FreeAccessContainer = as<React.ComponentClass>(withRouter(FreeAccessContainerPure));

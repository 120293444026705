import * as React from 'react';

import { AlertBarStoreType, IAlertBarStore } from '../../modules/alertBar/AlertBar.store';
import { AttachmentsStoreType, IAttachmentsStore, defaultAttachmentsPaging } from '../modules/attachments/attachments.store';
import { CurrentWorkspaceStoreType, ICurrentWorkspaceStore } from '../../modules/workspaces/currentWorkspace/CurrentWorkspace.store';
import { IUserStore, UserStoreType } from '../modules/user/user.store';
import { as, injectProps } from '../helpers/react.helpers';

import { SideNav } from '../components/SideNav';
import { TopNav } from '../components/TopNav';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';

interface IProps {
  children?: React.ReactElement<any>[] | React.ReactElement<any>;
  className?: string;
}

interface IInjectProps {
  userStore: IUserStore;
  currentWorkspaceStore: ICurrentWorkspaceStore;
  attachmentStore: IAttachmentsStore;
  alertBarStore: IAlertBarStore;
}

@injectProps({
  userStore: UserStoreType,
  currentWorkspaceStore: CurrentWorkspaceStoreType,
  attachmentStore: AttachmentsStoreType,
  alertBarStore: AlertBarStoreType,
})
@observer
class MainNavContainerPure extends React.Component<IProps & IInjectProps> {
  @autobind
  handleBeforeAttachmentClick() {
    this.props.attachmentStore.attachmentsPaging = defaultAttachmentsPaging;
  }

  render() {
    const workspace = this.props.currentWorkspaceStore.currentWorkspace!;
    const alertsCount = this.props.alertBarStore.alerts.length;

    const userRole = this.props.userStore.userInfo.role;
    const userLinkExtension = !!this.props.userStore.userInfo.queryString ? `?${this.props.userStore.userInfo.queryString}` : '';

    return (
      <>
        <SideNav
          userRole={userRole}
          userLinkExtension={userLinkExtension}
          workspaceRole={workspace.role}
          workspaceId={workspace.id}
          beforeAttachmentClick={this.handleBeforeAttachmentClick}
          alertsCount={alertsCount}
        />
        <TopNav alertsCount={alertsCount}/>
        {this.props.children}
      </>
    );
  }
}

export const MainNavContainer = as<React.ComponentClass<IProps>>(MainNavContainerPure);

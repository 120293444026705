import * as React from 'react';

import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { DisabledWhenWorkspaceOwnerPolicyExceeded } from '../../../containers/DisabledWhenWorkspaceOwnerPolicyExceeded';
import { EnableForRole } from '../../../containers/EnableForRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslatable } from '../../../helpers/translations.helpers';
import { InputStatus } from '../../../models/error.model';
import { ValidatedTextInput } from '../../../components/ValidatedTextInput';
import { WorkspaceRole } from '../../workspaces/workspaces.store';
import { as } from '../../../helpers/react.helpers';
import autobind from 'autobind-decorator';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { handleFolderNameChange } from '../../../helpers/formHelpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { withNamespaces } from 'react-i18next';

library.add(faFolderPlus);

export interface IAddAttachmentFolderModalProps {
  isModalOpen: boolean;
  canView: boolean;
  folderName: string;
  folderNameStatus: InputStatus;
  toggleModal(): void;
  onFolderNameChange(id: string): void;
  onAddFolderConfirmClicked(): void;
}

class AttachmentAddFolderPure extends React.Component<IAddAttachmentFolderModalProps & ITranslatable> {
  @autobind
  handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.props.onAddFolderConfirmClicked();
  }

  render() {
    return (
      <EnableForRole workspaceRoles={[WorkspaceRole.Owner, WorkspaceRole.Manager, WorkspaceRole.Developer, WorkspaceRole.Collaborator]} projectRoles={[]}>
        <DisabledWhenWorkspaceOwnerPolicyExceeded>
          <Button
            color="primary"
            className="attachments-button add-folder"
            disabled={!this.props.canView}
            title={this.props.canView ? '' : this.props.t('you_do_not_have_permission_to_perform_this_operation')}
            onClick={this.props.toggleModal}
          >
            <FontAwesomeIcon icon={faFolderPlus} />
            {this.props.t('attachment_add_folder')}
          </Button>
          <Modal className="modal-width-600" isOpen={this.props.isModalOpen} toggle={this.props.toggleModal}>
            <ModalHeader toggle={this.props.toggleModal}>{this.props.t('attachment_create_folder')}</ModalHeader>
            <Form className="custom-inline-form" onSubmit={this.handleSubmit}>
              <ModalBody>
                <div>
                  <Row>
                    <Col>
                      <ValidatedTextInput
                        type={'text'}
                        value={this.props.folderName}
                        labelText={this.props.t('attachment_add_folder_name')}
                        placeholder={''}
                        onChange={handleFolderNameChange(this.props.onFolderNameChange)}
                        feedbacks={this.props.folderNameStatus.errorCodes}
                        isValid={this.props.folderNameStatus.isValid}
                      />
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                  <Button
                    id="change-button"
                    color="success"
                    onClick={this.props.onAddFolderConfirmClicked}
                    disabled={!!!this.props.folderName || this.props.folderNameStatus.isValid === false}
                    className="mr-2"
                  >
                    {this.props.t('create')}
                  </Button>
                  <Button id="change-button-cancel" color="primary" className="btn-outline-primary" onClick={this.props.toggleModal}>
                    {this.props.t('cancel')}
                  </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </DisabledWhenWorkspaceOwnerPolicyExceeded>
      </EnableForRole>
    );
  }
}

export const AttachmentAddFolder = as<React.ComponentClass<IAddAttachmentFolderModalProps>>(withNamespaces('attachment', { wait: true })(AttachmentAddFolderPure));
